import React, {useContext} from "react"

import "./styles/App.scss"
import "../src/styles/index.css"
import {LogInRoutes, LogOutRoutes} from "./Routes"
import UserDataContext from "./context/UserDataContext"

function App() {
	const {isAuth} = useContext(UserDataContext)
	const {isLoading, userData} = useContext(UserDataContext)
	if(isLoading)
return
	return (
		<>
			{isAuth ? <LogInRoutes /> : <LogOutRoutes />}
		</>
	)
}

export default App
