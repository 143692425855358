import { motion } from "framer-motion";
import React, { useContext } from "react";
import UserDataContext from "../../../context/UserDataContext";
import BoxSacks from "../../reusable/BoxSacks";
import CounterDown from "../../reusable/CounterDown";
import NextScreenSectionDouble from "../../reusable/NextScreenSection";
import Typography from "../../reusable/typography/Typography";
import ReusableScreensRow from "./ReusableScreensRow";
import SectionRound from "../../reusable/SectionRound";

const WorkoutScreen = ({ data, isStoped }) => (
    <>
        {data.move || data.move === "" ? (
            <ReusableScreensRow
                seconds={data.seconds}
                isStoped={isStoped}
                img_src={data?.img_src && data.img_src}
                value={data.move}
                label={data.label}
                nextMove={data.next_screen}
                data={data}
            />
        ) : data.floor && data.bag ? (
            data.round === 1 || data.round === 3 ? (
                <BagFloorComponent1
                    nextBag={data.next_screen.bag}
                    nextFloor={data.next_screen.floor}
                    nextActiveRecovery={data.next_screen}
                    bag={data.bag}
                    note={data.note}
                    weight={data.weight}
                    floor={data.floor}
                    seconds={data.seconds}
                    round={data.round}
                    section={data.section}
                    isStoped={isStoped}
                    data={data}
                />
            ) : (
                <BagFloorComponent1
                    nextBag={data.next_screen.bag}
                    nextFloor={data.next_screen.floor}
                    nextWeight={data.next_screen.weight}
                    nextActiveRecovery={data.next_screen}
                    bag={data.bag}
                    note={data.note}
                    weight={data.weight}
                    floor={data.floor}
                    round={data.round}
                    section={data.section}
                    seconds={data.seconds}
                    isStoped={isStoped}
                    data={data}
                />
            )
        ) : null}
    </>
);
const BagFloorComponent1 = ({
    col2,
    bag,
    floor,
    weight,
    nextWeight,
    seconds,
    isStoped,
    nextBag,
    nextFloor,
    nextActiveRecovery,
    note,
    data,
    section,
}) => {
    const { showNextScreen } = useContext(UserDataContext);
    return (
        <div
            className="row justify-content-between"
            style={{
                height: "58vh",
                marginBottom: "45vh",
                padding: "0 15px",
            }}
        >
            <div
                className="col-sm-6 align-items-top  sidebag floor-div "
                style={{
                    background: "rgba(225,225,225,0.1)",
                    backdropFilter: "blur(8px)",
                    border: "1px solid rgba(225,225,225,0.3)",
                    borderRadius: "20px",
                    width: "49%",
                    boxShadow: "inset 0 0 50px 50px rgba(225,225,225,0.04)",
                }}
            >
                <div className="row align-items-center  justify-content-start g-0  ">
                    {bag && (
                        <BoxSacks
                            boxing={bag}
                            nextScreen={Boolean(
                                nextBag || nextActiveRecovery.move
                            )}
                        />
                    )}
                    {/* {nextBag && showNextScreen ? <BoxSacks label={"next bag"} boxing={nextBag} col2 /> : nextActiveRecovery && showNextScreen ? <BoxSacks label={"next active recovery"} boxing={nextActiveRecovery} col2 /> : null} */}
                </div>
            </div>

            {/* right floor */}
            <div
                style={{
                    background: "rgba(225,225,225,0.1)",
                    backdropFilter: "blur(8px)",
                    border: "1px solid rgba(225,225,225,0.3)",
                    borderRadius: "20px",
                    width: "49%",

                    boxShadow: "inset 0 0 50px 50px rgba(225,225,225,0.04)",
                }}
                className={`${
                    col2 ? "" : "pe-5"
                }  col-sm-6 align-items-top sidefloor floor-div `}
            >
                <FloorComponent floor={floor} note={note} weight={weight} />
                {/* <div className=" col-12">
		<SectionRound round={data?.round} section={data?.section}/>
		</div> */}
            </div>

            {seconds && (
                <CounterDown
                    className=""
                    totalSeconds={seconds}
                    isStoped={isStoped}
                />
            )}

            {((nextBag && showNextScreen) ||
                (nextActiveRecovery && showNextScreen)) && (
                <NextScreenSectionDouble
                    nextBag={nextBag}
                    nextFloor={nextFloor}
                    nextWeight={nextWeight}
                    nextActiveRecovery={nextActiveRecovery}
                />
            )}
        </div>
    );
};

export default WorkoutScreen;

export const FloorComponent = ({
    label = "floor",
    nextRound,
    weight,
    floor,
    note,
    col2 = false,
}) => {
    const { showNextScreen } = useContext(UserDataContext);
    const labelClassName = `text-white p-1 ${col2 ? "text-end" : "text-start"}`;

    return (
        <>
            {floor ? (
                <>
                    <div className="playtext-label  mt-2  ms-2 ">
                        <div className="d-flex ">
                            <div className="w-100">
                                {/* font size for next floor  */}

                                <Typography
                                    style={{
                                        fontSize: col2 ? "2rem" : "3rem",
                                        fontWeight: col2 ? "100" : "500",
                                        fontFamily: "'Roboto', sans-serif",
                                    }}
                                    semanticTag="h1"
                                    variant="heading1"
                                    className={labelClassName}
                                >
                                    {label?.toUpperCase()}
                                    {nextRound && `,ROUND ${nextRound}`}
                                </Typography>
                            </div>
                        </div>
                    </div>

                    <div
                        className={`${
                            col2 ? "col-12 " : "col-6 ms-2"
                        }  text-center playtext-value`}
                        style={{
                            height: col2 ? "auto" : "80%",
                            width: "90%",
                            overflowWrap: "break-word",
                            margin: "auto",
                            marginRight: "0px",
                        }}
                    >
                        <div>
                            <Typography
                                style={{
                                    textAlign: "justify",
                                    fontSize: col2 ? "2.7rem" : "10rem",
                                    fontWeight: col2 ? "900" : "200",
                                    fontFamily: col2 ? "Roboto" : "",
                                    marginTop: col2 ? "5px" : "1rem",
                                    letterSpacing: col2 ? "" : "5px",
                                    lineHeight: col2 ? "" : "11rem",
                                }}
                                semanticTag="h1"
                                variant="heading1"
                                className={` ${
                                    col2
                                        ? "text-red text-end"
                                        : "text-white text-start"
                                }`}
                            >
                                {floor?.toUpperCase()}
                            </Typography>
                        </div>
                        <div>
                            <Typography
                                style={{
                                    fontSize: col2 ? "4rem" : "2rem",
                                    fontWeight: "200",
                                }}
                                semanticTag="h1"
                                variant="heading1"
                                className={"text-start text-red"}
                            >
                                {weight?.toUpperCase()}
                            </Typography>
                        </div>
                        <div>
                            <Typography
                                style={{
                                    fontSize: "1.8rem",
                                    fontWeight: "100",
                                }}
                                semanticTag="h1"
                                variant="heading1"
                                className={"text-start text-red"}
                            >
                                {note?.toUpperCase()}
                            </Typography>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};
