import React, {useContext} from "react"
import UserDataContext from "../../context/UserDataContext"
import Typography from "./typography/Typography"
import UserCardLg from "./UserCardLg"

const NavBar = () => {
	const {isLoading, userData} = useContext(UserDataContext)
	const content = userData
	return (
		<>
			{isLoading ? <div className={"text-center pt-2"}><Typography variant="bodyLgBold" semanticTag="h1">Loading...</Typography></div> : <>
				<UserCardLg username={content[0]?.username} picture={content[0]?.picture} />
				<hr className="bg-white"/>
			</>}
		</>
	)
}

export default NavBar
