import React, { useContext } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import SignUpScreen from "./screens/SignUpScreen";
import EditSessionComponent from "./screens/EditSessionComponent";
import HomePage from "./screens/HomePage";
import ScreenPlay from "./screens/ScreenPlay";
import SessionCreationScreen from "./screens/SessionCreationScreen";
import { getHistoryContentById } from "./utility/fetch/fetchSessions";
import SessionDetailScreen from "./screens/SessionDetailScreen";
import SessionSchema from "./screens/SessionSchema";
import UserDataContext from "./context/UserDataContext";
import Layout from "./components/Layout";
import UsersList from "./screens/Users";

export const LogInRoutes = () => {
  const { isAuth } = useContext(UserDataContext);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/createSession" element={<SessionCreationScreen />} />

          <Route path="/user/:id/history/" element={<SessionDetailScreen />} />
          <Route path="/Users/" element={<UsersList />} />
          <Route path="/session-schema/" element={<SessionSchema />} />
          <Route path="update" element={<EditSessionComponent />}>
            <Route path=":id" element={<EditSessionComponent />} />
          </Route>
        </Route>
        <Route path="sessions" element={<ScreenPlay />}>
          <Route path=":id" element={<ScreenPlay />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export const LogOutRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<SignUpScreen />} />
      <Route path="*" element={<SignUpScreen />} />
      <Route path="sessions" element={<SignUpScreen />}>
        <Route path=":id" element={<SignUpScreen />} />
        <Route path="*" element={<SignUpScreen />} />
      </Route>
      <Route path="update" element={<SignUpScreen />}>
        <Route path=":id" element={<SignUpScreen />} />
        <Route path="*" element={<SignUpScreen />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
