/* eslint-disable complexity */

/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import WelcomeScreen1 from "./WelcomeScreen1";
import { AnimatePresence } from "framer-motion";
import WelcomeScreen2 from "./WelcomeScreen2";
import WelcomeScreen3 from "./WelcomeScreen3";
import WorkoutScreen from "./WorkoutScreen";
import ReusableScreensRow from "./ReusableScreensRow";
import { useNavigate } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
    PauseFill,
    PlayFill,
    SkipBackwardFill,
    SkipForwardFill,
    X,
} from "react-bootstrap-icons";
import { async } from "q";
import { postSessionHistory } from "../../../utility/fetch/fetchSessions";
import userAuth from "../../../utility/auth/authProvider";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IconButton, StepLabel } from "@mui/material";

const steps = [
    "Select campaign settings",
    "Create an ad group",
    "Create an ad",
    "Create an ad group",
    "Create an ad",
    "Create an ad group",
    "Create an ad",
];

const variants = {
    enter(direction) {
        return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0,
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
    },
    exit(direction) {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0,
        };
    },
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
// const swipeConfidenceThreshold = 10000
// const swipePower = (offset: number, velocity: number) => Math.abs(offset) * velocity

const ScreenShow = ({ session }) => {
    const userToken = userAuth.getUserToken();
    const userId = userAuth.getUserProfile().id;
    const [sessionProgress, setSessionProgress] = useState(0);
    const [isCloseShown, setIsCloseShown] = useState(true);
    const [isPlayerShown, setIsPlayerShown] = useState(false);
    const [isStoped, setIsStoped] = useState(false);
    const [pauseActive, setPauseActive] = useState(false);
    const [skippedData, setSkippedData] = useState([]);
    const [start_time] = useState(new Date());
    const navigate = useNavigate();
    const [[page, direction], setPage] = useState([0, 0]);
    const indexCounter = 0;
    const paginate = (newDirection) => {
        setPage([page + newDirection, newDirection]);
    };
    const paginate1 = (newDirection) => {
        setPage([newDirection, newDirection]);
    };

    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);

    // The required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50;

    const onTouchStart = (e) => {
        setTouchEnd(null); // Otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX);
    };

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

    const onClickHandler = (e) => {
        setIsStoped(!isStoped);
        setPauseActive(!pauseActive);
    };

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) {
            return;
        }

        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        if (isLeftSwipe) {
            goNext();
        }

        if (isRightSwipe) {
            goBack();
        }
        // Add your conditional logic here
    };

    let counter = 3;
    const { stretch, warm_up, fundamentals, rounds } = session;

    const secPerWarm_up = (60 * 3) / warm_up.length;
    const secStretch = (60 * 3) / stretch.length;

    console.log(stretch);

    const workouts = formatWorkout(session.rounds, {
        switch: session.switch,
        prepareToStart: session.prepare_to_start,
        prepareToStretch: session.prepare_to_stretch,
        firstMoveStretch: stretch[0].move,
    }).map((value) => ({ type: "round", ...value }));
    const dataWarm_up = warm_up.map((value, index) => ({
        type: "warm_up",
        move: value.move,
        seconds: value.seconds,
        nextMove: {
            label: warm_up[index + 1] ? "Next warm up" : "Next: Fundamentals",
            move: warm_up[index + 1]
                ? warm_up[index + 1].move
                : fundamentals[0].move,
        },
    }));

    const dataStretch = stretch.map((value, index) => ({
        type: "stretch",
        move: value.move,
        seconds: value.seconds,
        nextMove: {
            label: stretch[index + 1] ? "Next stretch" : "Next",
            move: stretch[index + 1] ? stretch[index + 1].move : "Thank you",
        },
    }));
    console.log(workouts, "workouts");
    const dataFundamentals = fundamentals.map((value, index) => ({
        type: "fundamentals",
        move: value.move,
        seconds: value.seconds,
        nextMove: {
            label: fundamentals[index + 1] ? "Next fundamental" : "Next",
            move: fundamentals[index + 1]
                ? fundamentals[index + 1]?.move
                : "Prepare to Start",
        },
    }));

    const dataSetScreens = [
        { seconds: 1 },
        { seconds: 60 },
        { seconds: 1 },
        ...dataWarm_up,
        ...dataFundamentals,
        ...workouts,
        ...dataStretch,
        { seconds: 1 },
    ];

    // const roundObjects = dataSetScreens.filter(item => item.type === "round");

    const roundObjects = dataSetScreens
        .map((item, index) => ({ ...item, index })) // Add the 'index' property
        .filter((item) => item.type === "round");

    const uniqueRoundSection = new Set();
    const filteredData = [];

    for (const item of roundObjects) {
        const roundSection = `${item.round}-${item.section}`;
        if (!uniqueRoundSection.has(roundSection)) {
            filteredData.push(item);
            uniqueRoundSection.add(roundSection);
        }
    }

    // console.log(workouts)

    // Const staticPageSeconds = dataSetScreens[page]?.seconds
    const totalSeconds = dataSetScreens.reduce(
        (acc, curr) => acc + curr.seconds,
        0
    );
    const [sessionProgressLeft, setSessionProgressLeft] =
        useState(totalSeconds);
    const [pageSeconds, setPageSeconds] = useState(
        dataSetScreens[page]?.seconds
    );

    // // console.log("Total seconds left: ", sessionProgressLeft)
    // // console.log("Total seconds progress: ", sessionProgress)
    // // console.log("Total page seconds: ", pageSeconds)

    useEffect(() => {
        const interval = setInterval(() => {
            isStoped
                ? setSessionProgressLeft(sessionProgressLeft)
                : setSessionProgressLeft(sessionProgressLeft - 1);
            isStoped
                ? setSessionProgress(sessionProgress)
                : setSessionProgress(sessionProgress + 1);

            if (pageSeconds === 0) {
                paginate(1);
                isStoped
                    ? setPageSeconds(pageSeconds)
                    : setPageSeconds(dataSetScreens[page + 1]?.seconds);
            } else {
                isStoped
                    ? setPageSeconds(pageSeconds)
                    : setPageSeconds(pageSeconds - 1);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [sessionProgressLeft, isStoped]);

    const goNext = () => {
        setSkippedData([...skippedData, dataSetScreens[page]]);
        // console.log(skippedData,"pages");
        const desiredEndPage =
            stretch.length +
            warm_up.length +
            fundamentals.length +
            workouts.length +
            3;

        if (page === desiredEndPage) {
            handleEndOfScreen();
        }
        page === desiredEndPage ? navigate("/") : paginate(1);
        setSessionProgressLeft(sessionProgressLeft - pageSeconds);
        setSessionProgress(
            sessionProgress +
                (dataSetScreens[page].seconds - pageSeconds === 0
                    ? dataSetScreens[page].seconds
                    : dataSetScreens[page].seconds - pageSeconds)
        );
        setPageSeconds(dataSetScreens[page + 1]?.seconds);
        setIsStoped(true);
    };

    const goBack = () => {
        page === 0 ? navigate("/") : paginate(-1);
        // SetIsStoped(true)
        setSessionProgressLeft(
            sessionProgressLeft +
                (dataSetScreens[page].seconds - pageSeconds === 0
                    ? dataSetScreens[page].seconds
                    : dataSetScreens[page].seconds - pageSeconds)
        );
        setSessionProgress(sessionProgress - pageSeconds);
        setPageSeconds(dataSetScreens[page - 1]?.seconds);
        setIsStoped(true);
    };

    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.code === "Scape" || event.keyCode === 27) {
                navigate("/");
                event.preventDefault();
            }

            if (event.code === "ArrowLeft" || event.keyCode === 37) {
                goBack();
                event.preventDefault();
            }

            if (event.code === "ArrowRight" || event.keyCode === 39) {
                goNext();
                event.preventDefault();
            }

            if (
                event.key === " " ||
                event.code === "Space" ||
                event.keyCode === 32
            ) {
                setIsStoped(!isStoped);
                setPauseActive(!pauseActive);
                event.preventDefault();
            }
        };
        setActiveStep(page);
        document.addEventListener("keydown", keyDownHandler);
        setIsStoped(false);
        pauseActive ? setIsStoped(true) : setIsStoped(false);

        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, [isStoped, page]);
    // useEffect(() => {
    //   const desiredEndPage = stretch.length + warm_up.length + fundamentals.length + workouts.length + 3;

    //   if (page === desiredEndPage) {
    //     handleEndOfScreen();
    //   }
    // }, [page]);
    const handleEndOfScreen = async () => {
        let end_time = new Date();

        const response = await postSessionHistory(
            {
                start_time,
                end_time,
                skippedData,
                session: session.id,
                user: userId,
            },
            userToken
        );
        // console.log(response);

        // console.log(start_time,end_time,skippedData,"skipped data........................");
    };

    // Replace `desiredEndPage` with the page index representing the end of the screen

    const [activeStep, setActiveStep] = React.useState();
    const [completed, setCompleted] = React.useState({});

    // ----------------- page step

    const handleStep = (step) => () => {
        setActiveStep(page);
        paginate1(step);
    };

    const isActive = (sectionNumber, currentPage, currentRound) => {
        const indexes = [];
        for (let i = 0; i < dataSetScreens.length; i++) {
            if (
                dataSetScreens[i].section === sectionNumber &&
                dataSetScreens[i].round === currentRound
            ) {
                indexes.push(i);
            }
        }
        return indexes.includes(currentPage);
    };

    return (
        <>
            {/* <Box sx={{ width: '100%',height:"100vh" }}>
      <Stepper nonLinear activeStep={activeStep} style={{width:"100% ",overflow:"auto"}} >
        {filteredData.map((data, index) => (
          <Step key={data} completed={completed[index]} > 
            {
            data.section==1?
        <div onClick={handleStep(data.index)} className="rounded-circle border d-flex justify-content-center" style={{height:"25px ",width:"25px",cursor:"pointer"}}>
          {data.round}
        </div>:
        <IconButton onClick={handleStep(data.index)}>
       <FiberManualRecordIcon style={{color:"red"}}/>
      </IconButton>
            }
            
          </Step>
        ))}
      </Stepper>
      <div> */}

            <React.Fragment>
                <div
                    style={{
                        maxHeight: "100vh",
                        overflow: "hidden", // Hide overflow content if it exceeds 100vh
                        fontFamily: "TIMMONS NY",
                        fontWeight: 100,
                        letterSpacing: "2px",
                        color: "#ff002f",
                        position: "relative", // Add this to establish a new stacking context for child elements
                        backgroundImage: "url(/bg-logo.png)",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                    }}
                    className={"position"}
                    onTouchStart={onTouchStart}
                    onTouchMove={onTouchMove}
                    onTouchEnd={onTouchEnd}
                    onClick={onClickHandler}
                >
                    <Stepper
                        nonLinear
                        activeStep={activeStep}
                        style={{
                            width: "100% ",
                            overflow: "visible",
                            margin: "16px 0",
                        }}
                    >
                        {filteredData.map((data, index) => (
                            <Step key={data} completed={completed[index]}>
                                {data.section == 1 ? (
                                    <div
                                        onClick={handleStep(data.index)}
                                        className=" border d-flex justify-content-center align-items-center"
                                        style={{
                                            height: "34px ",
                                            width: "24px",
                                            cursor: "pointer",
                                            backgroundColor: isActive(
                                                data.section,
                                                page,
                                                data.round
                                            )
                                                ? "#fff"
                                                : "",
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "32px",
                                                color: isActive(
                                                    data.section,
                                                    page,
                                                    data.round
                                                )
                                                    ? "black"
                                                    : "#fff",
                                            }}
                                        >
                                            {data.round}
                                        </span>
                                    </div>
                                ) : (
                                    <IconButton
                                        onClick={handleStep(data.index)}
                                    >
                                        <FiberManualRecordIcon
                                            style={{
                                                color: isActive(
                                                    data.section,
                                                    page,
                                                    data.round
                                                )
                                                    ? "red"
                                                    : "#FFF",
                                            }}
                                        />
                                    </IconButton>
                                )}
                            </Step>
                        ))}
                    </Stepper>
                    <AnimatePresence initial={false} custom={direction}>
                        {page === 0 && (
                            <WelcomeScreen1
                                key={page}
                                seconds={dataSetScreens[page].seconds}
                            />
                        )}
                        {page === 1 && (
                            <WelcomeScreen2
                                nextMove={{
                                    label: "Next: Warm up",
                                    move: warm_up[0].move,
                                }}
                                quote={session.quote}
                                key={page}
                                seconds={dataSetScreens[page].seconds}
                                isStoped={isStoped}
                            />
                        )}
                        {page === 2 && (
                            <WelcomeScreen3
                                key={page}
                                seconds={dataSetScreens[page].seconds}
                            />
                        )}
                        {dataWarm_up.map((value, index) => (
                            <div key={3 + index}>
                                {page === 3 + index && (
                                    <ReusableScreensRow
                                        value={value.move}
                                        label={"Warm up"}
                                        seconds={value.seconds}
                                        isStoped={isStoped}
                                        nextMove={value.nextMove}
                                    />
                                )}
                            </div>
                        ))}
                        {dataFundamentals?.map((value, index) => (
                            <div key={3 + warm_up.length + index}>
                                {page === 3 + warm_up.length + index && (
                                    <ReusableScreensRow
                                        img_src={
                                            value?.img_src && value.img_src
                                        }
                                        value={value.move}
                                        label={"Fundamentals"}
                                        seconds={value.seconds}
                                        isStoped={isStoped}
                                        nextMove={value.nextMove}
                                    />
                                )}
                            </div>
                        ))}
                        {workouts.map((data, i) => {
                            const { boxing, workout } = data;
                            const key =
                                i + warm_up.length + fundamentals.length + 3;
                            const pageNumber = counter;
                            counter++;
                            return (
                                <div key={key}>
                                    {page === key && (
                                        <WorkoutScreen
                                            data={data}
                                            isStoped={isStoped}
                                        />
                                    )}
                                </div>
                            );
                        })}
                        {dataStretch.map((value, index) => (
                            <div
                                key={
                                    index +
                                    warm_up.length +
                                    fundamentals.length +
                                    workouts.length +
                                    3
                                }
                            >
                                {page ===
                                    index +
                                        warm_up.length +
                                        fundamentals.length +
                                        workouts.length +
                                        3 && (
                                    <ReusableScreensRow
                                        isStopped={isStoped}
                                        seconds={value.seconds}
                                        value={value.move}
                                        label={"Stretch"}
                                        nextMove={value.nextMove}
                                    />
                                )}
                            </div>
                        ))}

                        {page ===
                            stretch.length +
                                warm_up.length +
                                fundamentals.length +
                                workouts.length +
                                3 && (
                            <ReusableScreensRow
                                value={""}
                                img_src={session.thankyou}
                                label={"Thank you"}
                            />
                        )}
                    </AnimatePresence>
                    {/* {page === 0 ? <Button className="position-absolute top-50 start-0 translate-middle-y" bgColor="dark" href={"/"} label={"<"} /> : <Button className="position-absolute top-50 start-0 translate-middle-y" bgColor="dark" onClick={() => paginate(-1)} label={"<"} /> }
			{page === stretch.length + warm_up.length + fundamentals.length + workouts.length + 3 ? <Button className="position-absolute top-50 end-0 translate-middle-y" bgColor="dark" href={"/"} label={">"} /> : <Button className="position-absolute top-50 end-0 translate-middle-y" bgColor="dark" onClick={() => paginate(1)} label={">"} /> } */}

                    {/* Player */}
                    <div
                        className="position-absolute bottom-0 start-50 translate-middle-x mb-5 py-2 px-3 rounded rounded-5"
                        style={{
                            width: 200,
                            zIndex: 11,
                            backgroundColor: isPlayerShown
                                ? "rgba(0, 0, 0, 0.5)"
                                : "transparent",
                        }}
                        onMouseOver={() => setIsPlayerShown(true)}
                        onMouseLeave={() => setIsPlayerShown(false)}
                    >
                        {isPlayerShown && (
                            <div
                                className="d-flex justify-content-between text-center g-5 text-white w-100"
                                style={{
                                    width: "100%",
                                }}
                            >
                                <SkipBackwardFill
                                    className={"pointer"}
                                    onClick={() => {
                                        page === 0
                                            ? navigate("/")
                                            : paginate(-1);
                                        setIsStoped(false);
                                    }}
                                    size={28}
                                />
                                {isStoped ? (
                                    <PlayFill
                                        className={"pointer"}
                                        onClick={() => {
                                            setIsStoped(!isStoped);
                                        }}
                                        size={28}
                                    />
                                ) : (
                                    <PauseFill
                                        className={"pointer"}
                                        onClick={() => {
                                            setIsStoped(!isStoped);
                                        }}
                                        size={28}
                                    />
                                )}
                                <SkipForwardFill
                                    className={"pointer"}
                                    onClick={() => {
                                        page ===
                                        stretch.length +
                                            warm_up.length +
                                            fundamentals.length +
                                            workouts.length +
                                            3
                                            ? navigate("/")
                                            : paginate(1);
                                        setIsStoped(false);
                                    }}
                                    size={28}
                                />
                                <X
                                    className={"pointer text-white"}
                                    onClick={() => {
                                        navigate("/");
                                    }}
                                    size={28}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>

            {/* </div>
    </Box> */}
        </>
    );
};

export default ScreenShow;

const formatWorkout = (rounds, data) => {
    const roundOrder = rounds.sort((a, b) => a.round_number - b.round_number);
    const workoutList = [];
    for (let i = 0; i < roundOrder.length; i++) {
        let round = roundOrder[i];
        let nextRound = roundOrder[i + 1];
        let workouts = round.sections;
        round.round_number = round.round_number || i + 1;
        if (round.round_number === 1) {
            workoutList.push({
                round: round.round_number,
                section: 1,
                img_src: data.prepareToStart ? data.prepareToStart : null,
                move: "",
                label: "Prepare to start",
                seconds: 60,
                next_screen: {
                    bag: workouts[0]?.bag_1,
                    floor: workouts[0]?.floor_1,
                    weight: workouts[0].floor_weight_1 || "",
                },
            });
        }
        for (let i = 0; i < workouts.length; i++) {
            if (workouts[i].bag_1 && workouts[i].floor_1) {
                workoutList.push({
                    round: round.round_number,
                    section: i + 1,
                    bag: workouts[i].bag_1,
                    floor: workouts[i].floor_1,
                    weight: workouts[i].floor_weight_1 || "",
                    note: workouts[i].floor_bag_notes_1,
                    seconds: workouts[i].floor_bag_time_1,
                    next_screen: {
                        bag: workouts[i].bag_2,
                        floor: workouts[i].floor_2,
                    },
                });
            }

            if (workouts[i].bag_2 && workouts[i].floor_2) {
                if (
                    round.round_number === rounds.length &&
                    !workouts[i].bag_3 &&
                    !workouts[i].floor_3
                ) {
                    workoutList.push({
                        round: round.round_number,
                        section: i + 1,
                        bag: workouts[i].bag_2,
                        floor: workouts[i].floor_2,
                        weight: workouts[i].floor_weight_2 || "",
                        note: workouts[i].floor_bag_notes_2,
                        seconds: workouts[i].floor_bag_time_2,
                        next_screen:
                            i + 1 === workouts.length
                                ? {
                                      label: "Next: Prepare to stretch",
                                      move: "Finish, prepare to stretch",
                                  }
                                : workouts[i]?.active_recovery
                                ? {
                                      label: "Next Active recovery",
                                      move: workouts[i].active_recovery,
                                  }
                                : {
                                      bag: workouts[i + 1]?.bag_1,
                                      floor: workouts[i + 1]?.floor_1,
                                  },
                    });
                } else if (workouts[i]?.bag_3 && workouts[i]?.floor_3) {
                    workoutList.push({
                        round: round.round_number,
                        section: i + 1,
                        bag: workouts[i].bag_2,
                        floor: workouts[i].floor_2,
                        weight: workouts[i].floor_weight_2 || "",
                        note: workouts[i].floor_bag_notes_2,
                        seconds: workouts[i].floor_bag_time_2,
                        next_screen: {
                            label:
                                workouts[i].act_rec_time === 15
                                    ? "Next Active recovery"
                                    : null,
                            move:
                                workouts[i].act_rec_time === 15
                                    ? workouts[i].active_recovery
                                    : null,
                            bag:
                                workouts[i].act_rec_time !== 15
                                    ? workouts[i].bag_3
                                    : null,
                            floor:
                                workouts[i].act_rec_time !== 15
                                    ? workouts[i].floor_3
                                    : null,
                        },
                    });
                } else {
                    workoutList.push({
                        round: round.round_number,
                        section: i + 1,
                        bag: workouts[i].bag_2,
                        floor: workouts[i].floor_2,
                        weight: workouts[i].floor_weight_2 || "",
                        note: workouts[i].floor_bag_notes_2,
                        seconds: workouts[i].floor_bag_time_2,
                        next_screen: workouts[i]?.active_recovery
                            ? {
                                  label: "Next Active recovery",
                                  move: workouts[i].active_recovery,
                              }
                            : {
                                  bag: workouts[i + 1]?.bag_1,
                                  floor: workouts[i + 1]?.floor_1,
                              },
                    });
                }
            }

            if (
                workouts[i].active_recovery &&
                workouts[i].act_rec_time === 15
            ) {
                workoutList.push({
                    round: round.round_number,
                    section: i + 1,
                    move: workouts[i].active_recovery,
                    label: "Active recovery",
                    seconds: workouts[i].act_rec_time,
                    next_screen: {
                        bag: workouts[i]?.bag_3
                            ? workouts[i]?.bag_3
                            : workouts[i + 1]?.bag_1,
                        floor: workouts[i]?.floor_3
                            ? workouts[i]?.floor_3
                            : workouts[i + 1]?.floor_1,
                    },
                });
            }

            if (workouts[i]?.bag_3 && workouts[i]?.floor_3) {
                if (round.round_number === rounds.length) {
                    workoutList.push({
                        round: round.round_number,
                        section: i + 1,
                        bag: workouts[i].bag_3,
                        floor: workouts[i].floor_3,
                        weight: workouts[i].floor_weight_3 || "",
                        note: workouts[i].floor_bag_notes_3,
                        seconds: workouts[i].floor_bag_time_3,
                        next_screen: {
                            label:
                                workouts[i].active_recovery &&
                                workouts[i].act_rec_time !== 15
                                    ? "Next Active recovery"
                                    : "Next: Prepare to stretch",
                            move:
                                workouts[i].active_recovery &&
                                workouts[i].act_rec_time !== 15
                                    ? workouts[i].active_recovery
                                    : "Finish, prepare to stretch",
                        },
                    });
                } else {
                    workoutList.push({
                        round: round.round_number,
                        section: i + 1,
                        bag: workouts[i].bag_3,
                        floor: workouts[i].floor_3,
                        weight: workouts[i].floor_weight_3 || "",
                        note: workouts[i].floor_bag_notes_3,
                        seconds: workouts[i].floor_bag_time_3,
                        next_screen: workouts[i]?.active_recovery
                            ? {
                                  label: "Next Active recovery",
                                  move: workouts[i].active_recovery,
                              }
                            : {
                                  bag: workouts[i + 1]?.bag_1,
                                  floor: workouts[i + 1]?.floor_1,
                              },
                    });
                }
            }

            if (
                workouts[i].active_recovery &&
                workouts[i].act_rec_time !== 15
            ) {
                workoutList.push({
                    round: round.round_number,
                    section: i + 1,
                    move: workouts[i].active_recovery,
                    label: "Active recovery",
                    seconds: workouts[i].act_rec_time,
                    next_screen: {
                        bag: workouts[i + 1]?.bag_1,
                        floor: workouts[i + 1]?.floor_1,
                    },
                });
            }
        }

        if (round.round_number === rounds.length) {
            workoutList.push({
                round: round.round_number,
                move: "",
                img_src: data.prepareToStretch ? data.prepareToStretch : null,
                label: "Finish, prepare for stretching",
                seconds: 60,
                next_screen: {
                    label: "Next: stretch",
                    move: data.firstMoveStretch,
                    round: nextRound ? nextRound.round_number : null,
                },
            });
        } else {
            workoutList.push({
                round: round.round_number,
                move: "",
                img_src: data.switch ? data.switch : null,
                label: "Switch Floorsaa",
                seconds: 60,
                next_screen: {
                    bag: nextRound.sections[0]?.bag_1,
                    floor: nextRound.sections[0]?.floor_1,
                    round: nextRound ? nextRound.round_number : null,
                },
            });
        }
    }

    return workoutList;
};
