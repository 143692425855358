function lastUpdateHelper(date) {
    if(!date)
    return
    const now = new Date();
    const diff = now - new Date(date);

    // Define time intervals in milliseconds
    const minute = 60 * 1000;
    const hour = 60 * minute;
    const day = 24 * hour;

    if (diff < minute) {
        const secondsAgo = Math.floor(diff / 1000);
        return `${secondsAgo} sec ago`;
    } else if (diff < hour) {
        const minutesAgo = Math.floor(diff / minute);
        return `${minutesAgo} min ago`;
    } else if (diff < day) {
        const hoursAgo = Math.floor(diff / hour);
        return `${hoursAgo} hours ago`;
    } else {
        const daysAgo = Math.floor(diff / day);
        return `${daysAgo} days ago`;
    }
}

export default lastUpdateHelper