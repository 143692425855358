import React from "react"
import LogInForm from "../components/forms/LogInForm"
import Typography from "../components/reusable/typography/Typography"

const SignUpScreen = () => (
	<div className=" vh-80 d-flex align-items-center justify-content-center flex-column flex-sm-row ">
		<div>
			<div className=" d-flex justify-content-center pe-5">
			<div style={{width:'8rem'}}>
				<img className="" src="/lp-logo.png" alt="Lucky Punch Logo" width={'100%'}/>
			</div>
			</div>
			
			<div className=" d-flex justify-content-center">
			<LogInForm />
			</div>
		</div>
	
	</div>
)

export default SignUpScreen
