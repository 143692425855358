import React, { useState } from "react";
import Typography from "../../reusable/typography/Typography";
import { useFormikContext } from "formik";
import { useOutletContext } from "react-router-dom";

const FieldFormDropDownItem = ({
  name,
  option,
  setShowDropDown,
  round,
  schemaType,
  section,
  fieldName,
}) => {
  const [isMouseOver, setIsMouseOver] = React.useState(false);
  const onMouseOverClassName = "text-dark bg-white pointer px-2";
  const formik = useFormikContext();

  const { setCurrent } = useOutletContext();
  return (
    <div className="position-relative">
      <div
        className={isMouseOver ? onMouseOverClassName : "pointer px-2"}
        onMouseEnter={() => {
          setIsMouseOver(true);
          setCurrent(option);
        }}
        onMouseLeave={() => {
          setIsMouseOver(false);
          setCurrent(null);
        }}
        onClick={() => {
          // formik.setFieldValue(name, option.value);
          if (schemaType) {
            formik.setFieldValue(
              `rounds[${round}].sections[${section}].${fieldName}`,
              option.value
            );
            if (schemaType !== "Fundamentals" && round % 2 === 0) {
              formik.setFieldValue(
                `rounds[${round + 1}].sections[${section}].${fieldName}`,
                option.value
              );
            }
          } else {
            formik.setFieldValue(name, option.value);
          }
          setShowDropDown(false);
          setCurrent(null);
        }}
      >
        <div className="row align-items-center mb-1" style={{ height: 30 }}>
          <div className="col-12">
            <Typography semanticTag="p" variant="bodySm" className="mb-0">
              {option.label}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FieldFormDropDownItem;

export const FieldFormDropDownCategory = ({
  name,
  categoryData,
  section,
  schemaType,
  fieldName,
  round,
  setShowDropDown,
}) => {
  const [isMouseOverCategory, setIsMouseOverCategory] = useState(false);
  const [isMouseOverItem, setIsMouseOverItem] = useState(null); // Keep track of specific item index
  const onMouseOverClassName = "text-dark bg-white pointer px-2";

  const formik = useFormikContext();
  const { setCurrent } = useOutletContext();

  const handleClickItem = (event, item) => {
    event.stopPropagation();
    if (schemaType) {
      formik.setFieldValue(
        `rounds[${round}].sections[${section}].${fieldName}`,
        item.value
      );

      if (schemaType !== "Fundamentals" && round % 2 === 0) {
        formik.setFieldValue(
          `rounds[${round + 1}].sections[${section}].${fieldName}`,
          item.value
        );
      }
    } else {
      formik.setFieldValue(name, item.value);
    }

    setShowDropDown(false);
    setCurrent(null);
  };

  return (
    <div className="position-relative">
      <div
        className={isMouseOverCategory ? onMouseOverClassName : "pointer px-2"}
        // onMouseEnter={() => setIsMouseOverCategory(true)}
        // onMouseLeave={() => setIsMouseOverCategory(false)}
        // onClick={(e) => handleClickCategory(e)}
      >
        <div className="row align-items-center mb-1" style={{ height: 30 }}>
          <div className="col-6">
            <Typography semanticTag="p" variant="bodySm" className="mb-0">
              {categoryData.categoryName}
            </Typography>
          </div>
          <div className="col-6">
            {categoryData.categoryImage && (
              <img
                src={categoryData.categoryImage}
                style={{ height: 30, width: 30, resize: "inherit" }}
                alt={categoryData.categoryName}
              />
            )}
          </div>
        </div>
      </div>

      {categoryData?.itemsArray?.map((item, index) => (
        <div
          key={index}
          className={
            isMouseOverItem === index ? onMouseOverClassName : "pointer px-2"
          }
          onMouseEnter={() => setIsMouseOverItem(index)}
          onMouseLeave={() => setIsMouseOverItem(null)}
          onClick={(e) => handleClickItem(e, item)}
        >
          <div
            className="row align-items-center mb-1 ms-1"
            style={{ height: 25 }}
          >
            <div className="col-12">
              <Typography semanticTag="p" variant="bodySm" className="mb-0">
                {item.label}
              </Typography>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
