import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSessionHistoryById } from "../utility/fetch/fetchSessions";
import { Box, Pagination, Paper } from "@mui/material";
import TableMui from "../components/mui/TableMui";
import AdminButton from "../components/mui/AdminButton";
import HistoryDetail from "../components/sessionsCollection/HistoryDetail";
import userAuth from "../utility/auth/authProvider";
import Button from "../components/reusable/Button";

const SessionDetailScreen = () => {
  const {id}=useParams()
  const navigate=useNavigate()
const [data,setData]=useState()
const [current,setCurrent]=useState()
const [show,setShow]=useState(false)
  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    const result = await getSessionHistoryById(id,userAuth.getUserToken());
   setData(  result?.data?.histories);
   // console.log(result.data);
   // console.log(result?.data?.histories);

  }
  const blogDetail=(item)=>{
  
  
   setCurrent(item.skippedData)
setShow(true)
    }
  return <div>

<Box component={Paper} sx={{ marginBottom: "20px", padding: "20px" }}>
 

      

          <Box>
            <TableMui
              styleTableTh={{ fontWeight: "bold", whiteSpace: "nowrap" }}
             showId
              th={{
                    id: "id",
                    start_time:"Session Start",
                    end_time:"Session End",
                    skipped:"Workouts Skipped",
                    detail:"Action"
              }}
              td={data}
              customFields={[
                {name:"id",data:(value,item)=>(
                  < >
               {item.session?.title}
                </>
            )},
                {name:"detail",data:(value,item)=>(
                    <Button  onClick={()=>blogDetail(item)}
                    label="Detail"


                  />
              )},
              {name:"start_time",data:(value,item)=>(
                < >
                  {
                  new  Date( value).toUTCString()
        }
                </>
                )},
              {name:"end_time",data:(value,item)=>(
                < >
                  {
                  new  Date( value).toUTCString()
        }
                </>
                )},
              {name:"skipped",data:(value,item)=>(
                <p className="text-center" >
                  {
                 item?.skippedData?.length
        }
                </p>
                )},
            
              
             
              ]}
            />

          </Box>
         {show&& <HistoryDetail values={{show,setShow,current}}/>}
          </Box>
  </div>;
};

export default SessionDetailScreen;
