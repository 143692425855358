import React from "react"

import Button from "../../reusable/Button"
import Modal from "../../reusable/modal/Modal"
import UploadFilesManager from "./UploadFilesManager"

const UploadModal = ({fieldName}) => {
	const [modalOpen, setModalOpen] = React.useState(false)
	const closeModal = () => setModalOpen(false)

	return (
		<>
			<Button label={"Select File"} onClick={e => {
				e.preventDefault()
				setModalOpen(true)
			}} />
			{modalOpen && <Modal>
				<UploadFilesManager fieldName={fieldName} closeModal={closeModal} />
			</Modal>}
		</>
	)
}

export default UploadModal
