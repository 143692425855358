/* eslint-disable no-unused-expressions */
import React, { FC, useContext, useEffect, useState } from "react";
import UserDataContext from "../../context/UserDataContext";
import Typography from "./typography/Typography";

const CounterDown = ({ totalSeconds, isStoped, absolute = true }) => {
    const [counterDown, setCounterDown] = useState(totalSeconds);
    const { setShowNextScreen } = useContext(UserDataContext);

    useEffect(() => {
        const interval = setInterval(() => {
            isStoped
                ? setCounterDown(counterDown)
                : setCounterDown(counterDown > 0 && counterDown - 1);
        }, 1000);

        if (counterDown == totalSeconds) {
            setShowNextScreen(true);
        }

        return () => clearInterval(interval);
    }, [counterDown, isStoped]);

    return (
        <div
            className={absolute ? " text-center" : ""}
            style={{
                zIndex: 10,
                position: "fixed",
                left: 0,
                right: 0,
                bottom: "0px",
                // border: '2px solid green'
            }}
        >
            <Typography
                style={{
                    fontSize: "12rem",
                    fontWeight: "200",
                }}
                semanticTag="h1"
                variant="heading1"
                className="text-white"
            >
                {counterDown}
            </Typography>
        </div>
    );
};

export default CounterDown;
