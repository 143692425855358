/* eslint-disable no-unused-expressions */
/* eslint-disable complexity */
import { useFormikContext } from "formik";
import React, { useContext, useEffect, useState } from "react";
import Typography from "../../reusable/typography/Typography";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";
import { endpoint, getDataSelect } from "../fields/selectDataOptions";
import FieldFormDropDown from "../fields/FieldFormDropDown";
import AdminButton from "../../mui/AdminButton";
import FieldEditDropdown from "../../mui/FieldEditDropdown";
import UserDataContext from "../../../context/UserDataContext";

const SectionBoxForm = ({
  round,
  roundNumber,
  update,
  handleDataChange,
  schemaType,
  data,
  setData,
}) => {
  // console.log(round,"round/...........");
  const formik = useFormikContext();
  const [counter, setCounter] = useState(3);
  const { isAdmin } = useContext(UserDataContext);
  //   useEffect(()=>{
  // formik.resetForm()
  //   },[data])

  function onDelete() {
    handleDataChange(round, "round", {
      index: roundNumber,
      round,
      formik,
      remove: true,
    });
  }
  function onDuplicate() {
    handleDataChange(round, "round", { index: roundNumber, round, formik });
  }
  return (
    <div className="col-sm-6 col-12 text-center">
      <div className="border-1 border-dark my-3 rounded">
        <div className="d-flex mb-2  align-items-center justify-content-center">
          <Typography semanticTag="p" className="mb-0" variant="bodyLgBold">
            Section {roundNumber + 1}
          </Typography>
          <FieldEditDropdown onDelete={onDelete} onDuplicate={onDuplicate} />
        </div>

        {round.sections?.map((section, index) => (
          <SectionFieldGroup
            key={index + 1}
            schemaType={schemaType}
            section={index}
            sectionData={section}
            round={roundNumber}
            setCounter={setCounter}
            toggleOpen={index === round.sections.length - 1}
            noActiveRecovery={index === round.sections.length - 1}
            update={update}
            handleDataChange={handleDataChange}
          />
        ))}
      </div>
    </div>
  );
};

export default SectionBoxForm;

const SectionFieldGroup = ({
  noActiveRecovery,
  schemaType,
  round,
  section,
  handleDataChange,
}) => {
  const [index, setIndex] = useState(section);
  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const [floorOptions, setFloorOptions] = useState(null);
  const [weightOptions, setWeight] = useState([
    { label: "Light", value: "Light" },
    { label: "Heavy", value: "Heavy" },
    { label: "Medium", value: "Medium" },
  ]);
  const [bagOptions, setBagOptions] = useState(null);
  const [activeRecOptions, setActiveRecOptions] = useState(null);
  const formik = useFormikContext();
  const { touched, errors, values } = formik;
  const roundNumber = round + 1;
  const sectionData = formik.values.rounds[round]?.sections[section] || null;

  useEffect(() => {
    console.log("secVal", sectionData.bag_1, round, section);
  }, [sectionData]);
  useEffect(() => {
    getDataSelect(endpoint.floorMoves).then((response) => {
      setFloorOptions(response);
    });

    getDataSelect(endpoint.bagMoves).then((response) => {
      setBagOptions(response);
    });

    getDataSelect(endpoint.activeRecovery).then((response) => {
      setActiveRecOptions(response);
    });
  }, []);

  function onDelete() {
    handleDataChange(sectionData, "section", {
      index: section,
      round,
      formik,
      remove: true,
    });
  }
  function onDuplicate() {
    handleDataChange(sectionData, "section", {
      index: section,
      round,
      formik,
      remove: false,
    });
  }
  function onRecoveryDelete() {
    handleDataChange(sectionData, "active_recovery", {
      index: section,
      round,
      formik,
      remove: true,
    });
  }
  function onSubSectionChange(subSecIndex, remove) {
    handleDataChange(sectionData, "subsection", {
      index: section,
      subSecIndex,
      round,
      formik,
      remove,
    });
  }
  function onRecoveryAdd() {
    handleDataChange(sectionData, "active_recovery", {
      index: section,
      round,
      formik,
      remove: false,
    });
  }

  useEffect(() => {
    try {
      if (
        errors &&
        touched &&
        eval(`errors.rounds[${round}].sections[${section}]`) &&
        eval(`touched.rounds[${round}].sections[${section}]`) !== undefined
      ) {
        // console.log("eval.......",eval(`errors.rounds[${round}].sections[${section}]`) )

        setIsToggleOpen(true);
      }
    } catch (error) {}
  }, [errors, touched]);

  if (!sectionData) return;

  const floorCount = getFloorCount(sectionData);

  // if (floorCount === 2) {
  //   console.log(sectionData, round, section);
  // }

  let totalTime = 0;
  try {
    if (values) {
      for (let i = 1; i <= floorCount; i++) {
        const element = eval(
          `values.rounds[${round}].sections[${section}].floor_bag_time_${i}`
        );
        if (element) totalTime += element;
      }
      if ("active_recovery" in sectionData)
        totalTime += sectionData?.act_rec_time || 0;
    }
  } catch (error) {
    alert("error");
  }
  //console.log(sectionData, "datasec" + section + round);
  return (
    <>
      {isToggleOpen ? (
        <div className="row  text-start">
          <div className="col-12  " onClick={() => setIsToggleOpen(false)}>
            <div
              className={` d-flex pointer align-items-top justify-content-between border-bottom border-top border-dark border-1 py-3 px-3 mb-3 ${
                isToggleOpen ? "bg-dark rounded" : ""
              }`}
            >
              <Typography
                className={` mb-0 ${isToggleOpen ? "bg-dark" : ""}`}
                semanticTag="p"
                variant="bodyLg"
              >
                Round {section + 1}
              </Typography>

              <div
                className={`d-flex align-items-center ${
                  isToggleOpen ? "bg-dark rounded " : ""
                }`}
              >
                <div className="d-flex" onClick={() => setIsToggleOpen(false)}>
                  <Typography
                    className={`mb-0 text-end ${isToggleOpen ? "bg-dark" : ""}`}
                    semanticTag="p"
                    variant="bodySm"
                  >
                    {totalTime}
                  </Typography>
                  <CaretUpFill
                    size={18}
                    className={`text-end ${isToggleOpen ? "bg-dark" : ""}`}
                  />
                </div>
                <FieldEditDropdown
                  onClick={() => {
                    setIsToggleOpen(false);
                  }}
                  onDelete={onDelete}
                  onDuplicate={onDuplicate}
                />
                {/* <TrashFill size={18} className="pointer" onClick={() => handleDelete(item)}/> */}
              </div>
            </div>
          </div>

          <div className="  row justify-content-between align-items-center">
            <Typography
              className=" col-10 text-center mb-0"
              semanticTag="p"
              variant="bodyLg"
            >
              {floorCount > 0 &&
                `Floor - Bag: Group 1 - ${
                  sectionData.floor_bag_time_1 || "0"
                }sec`}
            </Typography>

            <div className="col-2">
              <FieldEditDropdown
                editText={"Add"}
                onDelete={
                  floorCount > 1 ? () => onSubSectionChange(1, true) : undefined
                }
                onDuplicate={
                  floorCount < 3
                    ? () => onSubSectionChange(1, false)
                    : undefined
                }
              />
            </div>
          </div>

          {floorCount > 0 && (
            <>
              <div className=" col-sm-6 col-12">
                {/* <FieldFormInput label="Bag" name={`rounds[${round}].sections[${section}].bag_1`} /> */}
                <FieldFormDropDown
                  typeValue="roundSection"
                  schemaType={schemaType}
                  index={0}
                  label="Bag 1"
                  name={`rounds[${round}].sections[${section}].bag_1`}
                  options={bagOptions}
                  round={round}
                  section={section}
                  fieldName={"bag_1"}
                  nextName={
                    (round === 0 ||
                      round === "0" ||
                      round === 2 ||
                      round === "2") &&
                    `rounds[${roundNumber}].sections[${section}].bag_1`
                  }
                />
              </div>
              <div className=" col-sm-6 col-12">
                {/* <FieldFormInput label="Floor" name={`rounds[${round}].sections[${section}].floor_1`} textarea /> */}

                <div className="row">
                  <div className="col-8">
                    <FieldFormDropDown
                      typeValue="roundSection"
                      schemaType={schemaType}
                      index={0}
                      label="Floor 1"
                      name={`rounds[${round}].sections[${section}].floor_1`}
                      options={floorOptions}
                      round={round}
                      section={section}
                      fieldName={"floor_1"}
                      nextName={
                        (round === 0 ||
                          round === "0" ||
                          round === 2 ||
                          round === "2") &&
                        `rounds[${roundNumber}].sections[${section}].floor_1`
                      }
                    />
                  </div>
                  <div className="d-flex col-4">
                    <FieldFormDropDown
                      typeValue="roundSection"
                      schemaType={schemaType}
                      index={0}
                      label="Weight 1"
                      name={`rounds[${round}].sections[${section}].floor_weight_1`}
                      options={weightOptions}
                      round={round}
                      section={section}
                      fieldName={"floor_weight_1"}
                      nextName={
                        (round === 0 ||
                          round === "0" ||
                          round === 2 ||
                          round === "2") &&
                        `rounds[${roundNumber}].sections[${section}].floor_1`
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="  col-sm-6 col-12">
                {/* <FieldFormInput label="Floor" name={`rounds[${round}].sections[${section}].floor_1`} textarea /> */}
                <FieldFormDropDown
                  typeValue="roundSection"
                  schemaType={schemaType}
                  index={0}
                  type={"number"}
                  min={0}
                  label="Floor Bag Time 1"
                  name={`rounds[${round}].sections[${section}].floor_bag_time_1`}
                  round={round}
                  section={section}
                  fieldName={"floor_bag_time_1"}
                  nextName={
                    (round === 0 ||
                      round === "0" ||
                      round === 2 ||
                      round === "2") &&
                    `rounds[${roundNumber}].sections[${section}].floor_1`
                  }
                />
              </div>

              <div className=" col-sm-6 col-12">
                <FieldFormDropDown
                  typeValue="roundSection"
                  schemaType={schemaType}
                  index={0}
                  type={"text"}
                  label="Floor Bag Notes 1"
                  name={`rounds[${round}].sections[${section}].floor_bag_notes_1`}
                  round={round}
                  section={section}
                  fieldName={"floor_bag_notes_1"}
                  nextName={
                    (round === 0 ||
                      round === "0" ||
                      round === 2 ||
                      round === "2") &&
                    `rounds[${roundNumber}].sections[${section}].floor_1`
                  }
                />
              </div>
            </>
          )}
          {floorCount >= 2 && (
            <div className="  row justify-content-between align-items-center">
              <Typography
                className=" col-10 text-center mb-0"
                semanticTag="p"
                variant="bodyLg"
              >
                {floorCount > 1 &&
                  `Floor - Bag: Group 2 - ${
                    sectionData.floor_bag_time_2 || "0"
                  }sec`}
              </Typography>

              <div className="col-2">
                <FieldEditDropdown
                  editText={"Add"}
                  onDelete={
                    floorCount > 1
                      ? () => onSubSectionChange(2, true)
                      : undefined
                  }
                  onDuplicate={
                    floorCount == 2
                      ? () => onSubSectionChange(2, false)
                      : undefined
                  }
                />
              </div>
            </div>
          )}
          {floorCount > 1 && (
            <>
              <div className=" col-sm-6 col-12">
                {/* <FieldFormInput label="Bag" name={`rounds[${round}].sections[${section}].bag_2`} /> */}
                <FieldFormDropDown
                  typeValue="roundSection"
                  schemaType={schemaType}
                  index={0}
                  label="Bag 2"
                  name={`rounds[${round}].sections[${section}].bag_2`}
                  options={bagOptions}
                  round={round}
                  section={section}
                  fieldName={"bag_2"}
                  nextName={
                    (round === 0 ||
                      round === "0" ||
                      round === 2 ||
                      round === "2") &&
                    `rounds[${roundNumber}].sections[${section}].bag_2`
                  }
                />
              </div>
              <div className=" col-sm-6 col-12">
                {/* <FieldFormInput label="Floor" name={`rounds[${round}].sections[${section}].floor_2`} textarea /> */}

                <div className="row">
                  <div className="col-8">
                    <FieldFormDropDown
                      typeValue="roundSection"
                      schemaType={schemaType}
                      index={0}
                      label="Floor 2"
                      name={`rounds[${round}].sections[${section}].floor_2`}
                      options={floorOptions}
                      round={round}
                      section={section}
                      fieldName={"floor_2"}
                      nextName={
                        (round === 0 ||
                          round === "0" ||
                          round === 2 ||
                          round === "2") &&
                        `rounds[${roundNumber}].sections[${section}].floor_2`
                      }
                    />
                  </div>
                  <div className="d-flex col-4">
                    <FieldFormDropDown
                      typeValue="roundSection"
                      schemaType={schemaType}
                      index={0}
                      label="Weight 2"
                      name={`rounds[${round}].sections[${section}].floor_weight_2`}
                      options={weightOptions}
                      round={round}
                      section={section}
                      fieldName={"floor_weight_2"}
                      nextName={
                        (round === 0 ||
                          round === "0" ||
                          round === 2 ||
                          round === "2") &&
                        `rounds[${roundNumber}].sections[${section}].floor_2`
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                {/* <FieldFormInput label="Floor" name={`rounds[${round}].sections[${section}].floor_1`} textarea /> */}
                <FieldFormDropDown
                  typeValue="roundSection"
                  schemaType={schemaType}
                  index={0}
                  type={"number"}
                  min={0}
                  label="Floor Bag Time 2"
                  name={`rounds[${round}].sections[${section}].floor_bag_time_2`}
                  round={round}
                  section={section}
                  fieldName={"floor_bag_time_2"}
                  nextName={
                    (round === 0 ||
                      round === "0" ||
                      round === 2 ||
                      round === "2") &&
                    `rounds[${roundNumber}].sections[${section}].floor_1`
                  }
                />
              </div>
              <div className=" col-sm-6 col-12">
                <FieldFormDropDown
                  typeValue="roundSection"
                  schemaType={schemaType}
                  index={0}
                  type={"text"}
                  label="Floor Bag Notes 2"
                  name={`rounds[${round}].sections[${section}].floor_bag_notes_2`}
                  round={round}
                  section={section}
                  fieldName={"floor_bag_notes_2"}
                  nextName={
                    (round === 0 ||
                      round === "0" ||
                      round === 2 ||
                      round === "2") &&
                    `rounds[${roundNumber}].sections[${section}].floor_2`
                  }
                />
              </div>
            </>
          )}

          <>
            {sectionData?.act_rec_time === 15 ? (
              <div className="  row justify-content-between align-items-center">
                <div className="col-10 d-flex justify-content-end align-items-center">
                  {"active_recovery" in sectionData &&
                    ![undefined, null].includes(
                      sectionData.active_recovery
                    ) && (
                      <Typography
                        className="text-center"
                        semanticTag="span"
                        variant="bodyLg"
                      >
                        {`Active Recovery - ${
                          sectionData?.act_rec_time || 0
                        }sec`}
                      </Typography>
                    )}
                </div>
                <div className="col-2">
                  <FieldEditDropdown
                    editText={"Add Active Recovery"}
                    onDelete={
                      "active_recovery" in sectionData
                        ? onRecoveryDelete
                        : undefined
                    }
                    onDuplicate={
                      !("active_recovery" in sectionData)
                        ? onRecoveryAdd
                        : undefined
                    }
                  />
                </div>
              </div>
            ) : null}

            {"active_recovery" in sectionData &&
              sectionData?.act_rec_time === 15 &&
              ![undefined, null].includes(sectionData.active_recovery) && (
                <>
                  <div className=" col-8">
                    <FieldFormDropDown
                      typeValue="roundSection"
                      schemaType={schemaType}
                      index={0}
                      label="Active Recovery"
                      name={`rounds[${round}].sections[${section}].active_recovery`}
                      options={activeRecOptions}
                      round={round}
                      section={section}
                      fieldName={"active_recovery"}
                      nextName={
                        (round === 0 ||
                          round === "0" ||
                          round === 2 ||
                          round === "2") &&
                        `rounds[${roundNumber}].sections[${section}].active_recovery`
                      }
                    />
                  </div>
                  <div className=" col-4">
                    <FieldFormDropDown
                      typeValue="roundSection"
                      schemaType={schemaType}
                      index={0}
                      type={"number"}
                      min={0}
                      label="Active Recovery Time"
                      name={`rounds[${round}].sections[${section}].act_rec_time`}
                      round={round}
                      section={section}
                      fieldName={"act_rec_time"}
                      nextName={
                        (round === 0 ||
                          round === "0" ||
                          round === 2 ||
                          round === "2") &&
                        `rounds[${roundNumber}].sections[${section}].act_rec_time`
                      }
                    />
                  </div>
                </>
              )}
          </>

          {floorCount === 3 && (
            <div className="  row justify-content-between align-items-center">
              <Typography
                className=" col-10 text-center mb-0"
                semanticTag="p"
                variant="bodyLg"
              >
                {floorCount === 3 &&
                  `Floor - Bag: Group 3 - ${
                    sectionData.floor_bag_time_3 || "0"
                  }sec`}
              </Typography>

              <div className="col-2">
                <FieldEditDropdown
                  onDelete={() => onSubSectionChange(3, true)}
                />
              </div>
            </div>
          )}

          {floorCount === 3 && (
            <>
              <div className=" col-sm-6 col-12">
                {/* <FieldFormInput label="Bag" name={`rounds[${round}].sections[${section}].bag_2`} /> */}
                <FieldFormDropDown
                  typeValue="roundSection"
                  schemaType={schemaType}
                  index={0}
                  label="Bag 3"
                  name={`rounds[${round}].sections[${section}].bag_3`}
                  options={bagOptions}
                  round={round}
                  section={section}
                  fieldName={"bag_3"}
                  nextName={
                    (round === 0 ||
                      round === "0" ||
                      round === 2 ||
                      round === "2") &&
                    `rounds[${roundNumber}].sections[${section}].bag_2`
                  }
                />
              </div>
              <div className="col-sm-6 col-12">
                {/* <FieldFormInput label="Floor" name={`rounds[${round}].sections[${section}].floor_2`} textarea /> */}

                <div className="row">
                  <div className="col-8">
                    <FieldFormDropDown
                      typeValue="roundSection"
                      schemaType={schemaType}
                      index={0}
                      label="Floor 3"
                      name={`rounds[${round}].sections[${section}].floor_3`}
                      options={floorOptions}
                      round={round}
                      section={section}
                      fieldName={"floor_3"}
                      nextName={
                        (round === 0 ||
                          round === "0" ||
                          round === 2 ||
                          round === "2") &&
                        `rounds[${roundNumber}].sections[${section}].floor_3`
                      }
                    />
                  </div>
                  <div className="d-flex col-4">
                    <FieldFormDropDown
                      typeValue="roundSection"
                      schemaType={schemaType}
                      index={0}
                      label="Weight 3"
                      name={`rounds[${round}].sections[${section}].floor_weight_3`}
                      options={weightOptions}
                      round={round}
                      section={section}
                      fieldName={"floor_weight_3"}
                      nextName={
                        (round === 0 ||
                          round === "0" ||
                          round === 2 ||
                          round === "2") &&
                        `rounds[${roundNumber}].sections[${section}].floor_3`
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                {/* <FieldFormInput label="Floor" name={`rounds[${round}].sections[${section}].floor_1`} textarea /> */}
                <FieldFormDropDown
                  typeValue="roundSection"
                  schemaType={schemaType}
                  index={0}
                  type={"number"}
                  min={0}
                  label="Floor Bag Time 3"
                  name={`rounds[${round}].sections[${section}].floor_bag_time_3`}
                  round={round}
                  section={section}
                  fieldName={"floor_bag_time_3"}
                  nextName={
                    (round === 0 ||
                      round === "0" ||
                      round === 2 ||
                      round === "2") &&
                    `rounds[${roundNumber}].sections[${section}].floor_1`
                  }
                />
              </div>
              <div className=" col-sm-6 col-12">
                <FieldFormDropDown
                  typeValue="roundSection"
                  schemaType={schemaType}
                  index={0}
                  type={"text"}
                  label="Floor Bag Notes 3"
                  name={`rounds[${round}].sections[${section}].floor_bag_notes_3`}
                  round={round}
                  section={section}
                  fieldName={"floor_bag_notes_3"}
                  nextName={
                    (round === 0 ||
                      round === "0" ||
                      round === 2 ||
                      round === "2") &&
                    `rounds[${roundNumber}].sections[${section}].floor_3`
                  }
                />
              </div>
            </>
          )}

          <>
            {sectionData?.act_rec_time !== 15 ? (
              <div className="  row justify-content-between align-items-center">
                <div className="col-10 d-flex justify-content-end align-items-center">
                  {"active_recovery" in sectionData &&
                    ![undefined, null].includes(
                      sectionData.active_recovery
                    ) && (
                      <Typography
                        className="text-center"
                        semanticTag="span"
                        variant="bodyLg"
                      >
                        {`Active Recovery - ${
                          sectionData?.act_rec_time || 0
                        }sec`}
                      </Typography>
                    )}
                </div>
                <div className="col-2">
                  <FieldEditDropdown
                    editText={"Add Active Recovery"}
                    onDelete={
                      "active_recovery" in sectionData
                        ? onRecoveryDelete
                        : undefined
                    }
                    onDuplicate={
                      !("active_recovery" in sectionData)
                        ? onRecoveryAdd
                        : undefined
                    }
                  />
                </div>
              </div>
            ) : null}

            {"active_recovery" in sectionData &&
              sectionData?.act_rec_time !== 15 &&
              ![undefined, null].includes(sectionData.active_recovery) && (
                <>
                  <div className=" col-8">
                    <FieldFormDropDown
                      typeValue="roundSection"
                      schemaType={schemaType}
                      index={0}
                      label="Active Recovery"
                      name={`rounds[${round}].sections[${section}].active_recovery`}
                      options={activeRecOptions}
                      round={round}
                      section={section}
                      fieldName={"active_recovery"}
                      nextName={
                        (round === 0 ||
                          round === "0" ||
                          round === 2 ||
                          round === "2") &&
                        `rounds[${roundNumber}].sections[${section}].active_recovery`
                      }
                    />
                  </div>
                  <div className=" col-4">
                    <FieldFormDropDown
                      typeValue="roundSection"
                      schemaType={schemaType}
                      index={0}
                      type={"number"}
                      min={0}
                      label="Active Recovery Time"
                      name={`rounds[${round}].sections[${section}].act_rec_time`}
                      round={round}
                      section={section}
                      fieldName={"act_rec_time"}
                      nextName={
                        (round === 0 ||
                          round === "0" ||
                          round === 2 ||
                          round === "2") &&
                        `rounds[${roundNumber}].sections[${section}].act_rec_time`
                      }
                    />
                  </div>
                </>
              )}
          </>
        </div>
      ) : (
        <div className="row justify-content-center text-start">
          <div className="col-12" onClick={() => setIsToggleOpen(true)}>
            <div className="d-flex pointer justify-content-between align-items-top border-bottom border-top border-dark border-1 py-3 px-3">
              <Typography className={"mb-0"} semanticTag="p" variant="bodyLg">
                Round {section + 1}
              </Typography>
              <div
                className={`d-flex align-items-center ${
                  isToggleOpen ? "bg-dark rounded " : ""
                }`}
              >
                <div className="d-flex" onClick={() => setIsToggleOpen(true)}>
                  <Typography
                    className={"mb-0"}
                    semanticTag="p"
                    variant="bodySm"
                  >
                    {/* {totalTime||section === 2 ? "120sec" : "150sec"} */}
                    {totalTime}
                  </Typography>
                  <CaretDownFill size={18} className={"text-end"} />
                </div>
                <FieldEditDropdown
                  onClick={() => {
                    setIsToggleOpen(false);
                  }}
                  onDelete={onDelete}
                  onDuplicate={onDuplicate}
                />
                {/* <TrashFill size={18} className="pointer" onClick={() => handleDelete(item)}/> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

function getFloorCount(data) {
  let lastIndex = -1;

  for (const key in data) {
    if (key.includes("_")) {
      const sectionIndex = parseInt(key.slice(key.lastIndexOf("_") + 1));
      if (!isNaN(sectionIndex) && sectionIndex > lastIndex) {
        lastIndex = sectionIndex;
      }
    }
  }
  return lastIndex;
}
