import { motion } from "framer-motion";
import React from "react";
import CounterDown from "../../reusable/CounterDown";
import { NextScreenSectionDynamic } from "../../reusable/NextScreenSection";

const WelcomeScreen1 = ({ seconds, isStoped }) => (
    <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
    >
        <div className="row align-items-center text-center vh-100">
            <div className="col-12 text-center">
                <img
                    src="/lp-logo.png"
                    className="img-fluid"
                    alt="Lucky Punch Logo"
                    width={500}
                    height={300}
                />
            </div>
            <CounterDown totalSeconds={seconds} isStoped={isStoped} />
        </div>
    </motion.div>
);

export default WelcomeScreen1;
