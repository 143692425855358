import React from "react"

const UserDataContext = React.createContext({
  showNextScreen: false,
  setShowNextScreen() {},
  files: [],
  filesLoading: false,
  userData: null,
  isLoading: false,
  isAuth: false,
  setIsAuth() {},
  logIn() {},
  logOut() {},
  authError: null,
  fetchFiles() {}
})

export default UserDataContext
