function removeAndShift(data, startingIndex) {
  
    let lastIndex = -1;

    for (const key in data) {
        if (key.includes("_")) {
            const sectionIndex = parseInt(key.slice(key.lastIndexOf("_") + 1));
            if (!isNaN(sectionIndex) && sectionIndex > lastIndex) {
                lastIndex = sectionIndex;
            }
        }
    }
    for (let index = startingIndex; index < lastIndex; index++) {
        const sourceIndex = index + 1;
        const targetIndex = index;
    
        for (const key in data) {
            if (key.endsWith(`_${sourceIndex}`)) {
                const targetKey = key.replace(`_${sourceIndex}`, `_${targetIndex}`);
                data[targetKey] = data[key];
            }
        }
    }
    for (const key in data) {
        if (key.endsWith(`_${lastIndex}`)) 
delete data[key]
        }
    
    


  
}
function addAndShift(data, indexToAdd) {
    let lastIndex = -1;

    for (const key in data) {
        if (key.includes("_")) {
            const sectionIndex = parseInt(key.slice(key.lastIndexOf("_") + 1));
            if (!isNaN(sectionIndex) && sectionIndex > lastIndex) {
                lastIndex = sectionIndex;
            }
        }
    }

    for (let index = lastIndex; index >= indexToAdd; index--) {
        const sourceIndex = index;
        const targetIndex = index + 1;

        for (const key in data) {
            if (key.endsWith(`_${sourceIndex}`)) {
                const targetKey = key.replace(`_${sourceIndex}`, `_${targetIndex}`);
                data[targetKey] = data[key];
            }
        }
    }

    // Add empty data for the new section
    for (const key in data) {
        if (key.endsWith(`_${indexToAdd}`)) {
            data[key] = "";
        }
    }
}
export {removeAndShift,addAndShift}