export function calculateTime(workoutData){


  // Calculate total duration in seconds
  let totalDurationInSeconds = 0;
  
  // Calculate warm-up duration
  const warmUpDuration = workoutData.warm_up.reduce((total, exercise) => total + exercise.seconds, 0);
  totalDurationInSeconds += warmUpDuration;
  
  // Calculate stretch duration
  const stretchDuration = workoutData.stretch.reduce((total, exercise) => total + exercise.seconds, 0);
  totalDurationInSeconds += stretchDuration;
  const funcdamentalsDuration = workoutData.fundamentals.reduce((total, exercise) => total + exercise.seconds, 0);
  totalDurationInSeconds += funcdamentalsDuration;
  
  // Calculate rounds' duration

  workoutData.rounds.forEach((round) => {
    round.sections.forEach((section) => {
      // Account for active recovery once
      if (section.act_rec_time) {
        totalDurationInSeconds += section.act_rec_time;
      }
  
      if (section.bag_1 && section.floor_bag_time_1) {
        totalDurationInSeconds += section.floor_bag_time_1;
      }
      if (section.bag_2 && section.floor_bag_time_2) {
        totalDurationInSeconds += section.floor_bag_time_2;
      }
      if (section.bag_3 && section.floor_bag_time_3) {
        totalDurationInSeconds += section.floor_bag_time_3;
      }

    });
  });
  
  const totalDurationInMinutes = Math.floor(totalDurationInSeconds / 60);
  return totalDurationInMinutes
  }