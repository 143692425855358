import React, { useEffect, useState } from "react";
import styles from "./Typography.module.scss";

var Variants;

(function (Variants) {
  Variants[(Variants["heading1"] = 0)] = "heading1";
  Variants[(Variants["heading2"] = 1)] = "heading2";
  Variants[(Variants["heading3"] = 2)] = "heading3";
  Variants[(Variants["bodyLg"] = 3)] = "bodyLg";
  Variants[(Variants["bodyLgBold"] = 4)] = "bodyLgBold";
  Variants[(Variants["bodySm"] = 5)] = "bodySm";
  Variants[(Variants["bodySmBold"] = 6)] = "bodySmBold";
})(Variants || (Variants = {}));

var SemanticTags;

(function (SemanticTags) {
  SemanticTags["h1"] = "h1";
  SemanticTags["h2"] = "h2";
  SemanticTags["h3"] = "h3";
  SemanticTags["h4"] = "h4";
  SemanticTags["h5"] = "h5";
  SemanticTags["h6"] = "h6";
  SemanticTags["p"] = "p";
  SemanticTags["div"] = "div";
  SemanticTags["span"] = "span";
  SemanticTags["li"] = "li";
})(SemanticTags || (SemanticTags = {}));

const Typography = ({
  variant,
  semanticTag,
  className,
  style,
  children,
  onClick,
  id,
  ref
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const classes = `${styles[variant]} ${className ? className : ""}`;
  const Tag = semanticTag ? SemanticTags[semanticTag] : "div";

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 767);
    };

    handleResize(); // Check initial screen size
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Tag
      ref={ref && ref}
      id={id ? id : ""}
      style={style ? style : {}}
      onClick={onClick}
      className={classes}
    >
      {children}
    </Tag>
  );
};

export default Typography;
