/* eslint-disable camelcase */
import userAuth from "../../../utility/auth/authProvider";
import { getOptions } from "../../../utility/fetch/fetchSessions";

export const getDataSelect = async (endpoint) => {
  const userToken = userAuth.getUserToken();
  // // console.log("userToken", userToken)
  const response = await getOptions(userToken, endpoint);
  // console.log("response", response);
  const moves = response.data?.data
    ? response?.data?.data?.attributes?.moves
    : [];

  let options = moves.map((move) => {
    const moveData = move.move;
    const category = move?.category
    ? {
        name: move.category?.data?.attributes?.name,
        image: move.category?.data?.attributes?.image?.data?.attributes?.url,
      }
    : null;
  
  return {
    label: moveData,
    value: moveData,
    ...(category && { category }),
  };
  });
  if(options.some(option => 'category' in option)){
 
  
  let groupedData = options.reduce((acc, curr) => {
      let category = curr.category?.name || "Others";
      let image = curr.category?.image || null;
  
      if (!acc[category]) {
          acc[category] = {
              categoryName: category,
              categoryImage: image,
              itemsArray: [],
          };
      }
  
      acc[category].itemsArray.push({
          label: curr.label,
          value: curr.value
      });
  
      return acc;
  }, {});
  
  // If you want to transform the object back to an array
  options = Object.values(groupedData);

let othersIndex = options.findIndex(item => item?.categoryName === 'Others');

if(othersIndex !== -1) {
    let othersCategory = options.splice(othersIndex, 1)[0];
    options.push(othersCategory);
}

  // console.log(options,"option");
  }
  return options;
};

export const endpoint = {
  floorMoves: "floor-move",
  bagMoves: "bag-move",
  activeRecovery: "active-recovery",
  warm_up: "warm-up",
  stretch: "stretch",
  fundamentals: "fundamentals",
};
