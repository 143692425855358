import React, { useEffect, useState } from "react";
import {
  getSessionContentById,
  getSessionContentBySlug,
  updateSessionContent,
} from "../utility/fetch/fetchSessions";
import { useParams } from "react-router-dom";
import Typography from "../components/reusable/typography/Typography";
import ScreenShow from "../components/screenPlay/screens/ScreenShow";
import { getDataSelect } from "../components/forms/fields/selectDataOptions";
import userAuth from "../utility/auth/authProvider";

const ScreenPlay = () => {
  const { id } = useParams();
  const [content, setContent] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const userToken = userAuth.getUserToken();
  const [data, setData] = useState({
    warm_ups: [],
    stretch: [],
  });
  const fetchContent = async () => {
    await getSessionContentById(id).then((res) => {
      setIsLoading(res.isLoading);
      setContent(res.data);
    });
  };
  async function fetchData() {
    const response = await getDataSelect("warm-up");

    const response2 = await getDataSelect("stretch");
    setData({ warm_ups: response, stretch: response2 });
  }

  useEffect(() => {
    updateLastUsed();
    fetchContent();
    fetchData();
  }, []);

  const updateLastUsed = async () => {
    const response = await updateSessionContent(
      { last_used: new Date() },
      id,
      userToken
    );
  };

  const session = isLoading || content.data.attributes;

  return (
    <div className="">
      {!isLoading ? (
        <ScreenShow session={session} />
      ) : (
        <div className={"text-center pt-2"}>
          <Typography variant="bodyLgBold" semanticTag="h1">
            Loading...
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ScreenPlay;
