import axios from "axios";

// Function Declaration
export const getSessionContent = async (email, userToken) => {
  try {
    const response = await axios.request({
      baseURL: process.env.REACT_APP_STRAPI_URL_BASE,
      headers: { Authorization: `Bearer ${userToken}` },
      url: `/api/users?filters[$and][0][email][$eq]=${email}&populate[role][populate]=*&populate[picture][populate]=*&populate[trainer_gif][populate]=*&populate[sessions][populate][rounds][populate]=*`,
    });

    return {
      data: response.data,
      isLoading: false,
    };
  } catch (e) {
    console.error(e);
    return {
      data: e,
      isLoading: false,
    };
  }
};

export const getSessionContentBySlug = async (slug) => {
  const url = `/api/sessions?filters[slug][$eq]=${slug}&populate[rounds][populate]=*&populate[warm_up][populate]=*&populate[stretch][populate]=*&populate[fundamentals][populate]=*`;
  try {
    const response = await axios.request({
      baseURL: process.env.REACT_APP_STRAPI_URL_BASE,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
      },
      url,
    });

    return {
      data: response.data,
      isLoading: false,
    };
  } catch (e) {
    console.error(e);
    return {
      data: e,
      isLoading: true,
    };
  }
};
export const getSessionsData = async (user,search) => {
  const url = `api/sessions?populate[rounds][populate]=*&populate[stretch][populate]=*&populate[fundamentals][populate]=*&populate[warm_up][populate]=*&sort=title:ASC&filters[$and][0][users_permissions_users][id][$eq]=${user.id}&filters[title][$containsi]=${search||""}`;
  try {
    const response = await axios.request({
      baseURL: process.env.REACT_APP_STRAPI_URL_BASE,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
      },
      url,
    });

    return {
      data: response.data,
      isLoading: false,
    };
  } catch (e) {
    console.error(e);
    return {
      data: e,
      isLoading: true,
    };
  }
};
export const deleteSessionContent = async (id, userToken) => {
  const url = `/api/sessions/${id}`;
  try {
    const response = await axios.request({
      baseURL: process.env.REACT_APP_STRAPI_URL_BASE,
      headers: { Authorization: `Bearer ${userToken}` },
      url,
      method: "DELETE",
    });

    return {
      data: response.data,
      isLoading: false,
    };
  } catch (e) {
    console.error(e);
    return {
      data: e,
      isLoading: true,
    };
  }
};
export const getHistoryContentById = async (slug) => {
  const url = `/api/histories?`;
  try {
    const response = await axios.request({
      baseURL: process.env.REACT_APP_STRAPI_URL_BASE,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
      },
      url,
    });

    return {
      data: response.data,
      isLoading: false,
    };
  } catch (e) {
    console.error(e);
    return {
      data: e,
      isLoading: true,
    };
  }
};
export const getSessionContentById = async (id) => {
  const url = `/api/sessions/${id}?&populate[rounds][populate]=*&populate[warm_up][populate]=*&populate[stretch][populate]=*&populate[fundamentals][populate]=*`;
  try {
    const response = await axios.request({
      baseURL: process.env.REACT_APP_STRAPI_URL_BASE,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
      },
      url,
    });

    return {
      data: response.data,
      isLoading: false,
    };
  } catch (e) {
    console.error(e);
    return {
      data: e,
      isLoading: true,
    };
  }
};

export const postSessionContent = async (values, userToken) => {
  const url = "/api/sessions";
  try {
    const response = await axios.request({
      baseURL: process.env.REACT_APP_STRAPI_URL_BASE,
      headers: { Authorization: `Bearer ${userToken}` },
      url,
      method: "POST",
      data: { data: values },
    });

    return {
      data: response.data,
      isLoading: false,
    };
  } catch (e) {
    console.error(e);
    return {
      data: e,
      isLoading: true,
    };
  }
};

export const updateSessionContent = async (values, id, userToken) => {
  const url = `/api/sessions/${id}`;
  try {
    const response = await axios.request({
      baseURL: process.env.REACT_APP_STRAPI_URL_BASE,
      headers: { Authorization: `Bearer ${userToken}` },
      url,
      method: "PUT",
      data: { data: values },
    });

    return {
      data: response.data,
      isLoading: false,
    };
  } catch (e) {
    console.error(e);
    return {
      data: e,
      isLoading: true,
    };
  }
};
export const getSessionHistoryById = async (id, userToken) => {
  const url = `/api/users/${id}?populate[histories][populate]=*`;
  try {
    const response = await axios.request({
      baseURL: process.env.REACT_APP_STRAPI_URL_BASE,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      url,
    });

    return {
      data: response.data,
      isLoading: false,
    };
  } catch (e) {
    console.error(e);
    return {
      data: e,
      isLoading: true,
    };
  }
};
export const getAllUsers = async (userToken) => {
  const url = `/api/users/?filters[$and][0][confirmed][$eq]=${true}&populate[histories][populate]=*`;
  try {
    const response = await axios.request({
      baseURL: process.env.REACT_APP_STRAPI_URL_BASE,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      url,
    });

    return {
      data: response.data,
      isLoading: false,
    };
  } catch (e) {
    console.error(e);
    return {
      data: e,
      isLoading: true,
    };
  }
};
export const postSessionHistory = async (values, userToken) => {
  const url = "/api/histories";
  try {
    const response = await axios.request({
      baseURL: process.env.REACT_APP_STRAPI_URL_BASE,
      headers: { Authorization: `Bearer ${userToken}` },
      url,
      method: "POST",
      data: { data: values },
    });

    return {
      data: response.data,
      isLoading: false,
    };
  } catch (e) {
    console.error(e);
    return {
      data: e,
      isLoading: true,
    };
  }
};

export const getSessionSchema = async (id) => {
  const url = `/api/session-schema/`;
  try {
    const response = await axios.request({
      baseURL: process.env.REACT_APP_STRAPI_URL_BASE,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
      },
      url,
    });

    return {
      data: response.data,
      isLoading: false,
    };
  } catch (e) {
    console.error(e);
    return {
      data: e,
      isLoading: true,
    };
  }
};
export const updateSessionSchema = async (values, userToken) => {
  const url = `/api/session-schema/`;
  try {
    const response = await axios.request({
      baseURL: process.env.REACT_APP_STRAPI_URL_BASE,
      headers: { Authorization: `Bearer ${userToken}` },
      url,
      method: "PUT",
      data: { data:  values  },
    });

    return {
      data: response.data,
      isLoading: false,
    };
  } catch (e) {
    console.error(e);
    return {
      data: e,
      isLoading: true,
    };
  }
};
export const getOptions = async (userToken, endpoint) => {
  const url = `/api/${endpoint}/?${
    [
      "warm-up",
      "stretch",
      "floor-move",
      "bag-move",
      "active-recoverys",
    ].includes(endpoint)
      ? "[populate][moves][populate][category][populate]=*"
      : "populate=*"
  }`;
  try {
    const response = await axios.request({
      method: "get",
      baseURL: process.env.REACT_APP_STRAPI_URL_BASE,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
      },
      url,
    });

    return {
      data: response.data,
      isLoading: false,
    };
  } catch (e) {
    console.error(e);
    return {
      data: e,
      isLoading: true,
    };
  }
};

export const getFiles = async (userToken) => {
  const url = "/api/upload/files";
  try {
    const response = await axios.request({
      method: "get",
      baseURL: process.env.REACT_APP_STRAPI_URL_BASE,
      headers: { Authorization: `Bearer ${userToken}` },
      url,
    });

    return {
      data: response.data,
      isLoading: false,
    };
  } catch (e) {
    console.error(e);
    return {
      data: e,
      isLoading: true,
    };
  }
};

export const postFile = async (values, userToken) => {
  const url = "/api/upload";

  try {
    const response = await axios.request({
      method: "post",
      baseURL: process.env.REACT_APP_STRAPI_URL_BASE,
      headers: {
        Authorization: `Bearer ${userToken}`,
        "content-type": "application/x-www-form-urlencoded",
      },
      url,
      data: values,
    });

    return {
      data: response.data,
      isLoading: false,
    };
  } catch (e) {
    console.error(e);
    return {
      data: e,
      isLoading: true,
    };
  }
};
