/* eslint-disable camelcase */
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import userAuth from "../../utility/auth/authProvider";
import { updateSessionContent } from "../../utility/fetch/fetchSessions";
import Button from "../reusable/Button";
import FieldFormInput from "./fields/FieldFormIntput";
import SectionBoxForm from "./sections/SectionBoxForm";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";
import SectionDynamicForm from "./sections/SectionDynamicForm";
import { calculateTime } from "../calculateTime";
import { removeAndShift, addAndShift } from "../../utility/subSectionHelpers";
// Import {getFloorDataSelect} from "./fields/selectDataOptions"

const SessionCreationSquema = yup.object().shape({
  title: yup.string().required("required"),
  quote: yup.string().required("required"),
  warm_up: yup
    .array()
    .of(
      yup.object().shape({
        move: yup.string().required("required"),
        seconds: yup.number().required("required"),
      })
    )
    .min(1, "required"),
  stretch: yup
    .array()
    .of(
      yup.object().shape({
        move: yup.string().required("required"),
        seconds: yup.number().required("required"),
      })
    )
    .min(1, "required"),
  fundamentals: yup.array().of(
    yup.object().shape({
      move: yup.string().required("required"),
      seconds: yup.number().required("required"),
    })
  ),
  users_permissions_users: yup.number(),
  rounds: yup
    .array()
    .of(
      yup.object().shape({
        // Required("Round number is required"),
        sections: yup
          .array()
          .of(
            yup.object().shape({
              bag_1: yup.string().required("required"), // Required("Section number is required"),
              floor_1: yup.string().required("required"), // Required("Section number is required"),
              floor_bag_time_1: yup.number(),
            })
          )
          .min(1, "At least one section is required"),
      })
    )
    .min(1, "At least 1 round is required"),
  // .required("Rounds are required"),
});

const SessionEditionForm = ({ id, attributes }) => {
  const sessionId = id;
  const userId = userAuth.getUserProfile().id;
  const userToken = userAuth.getUserToken();
  const [rounds, setRounds] = useState([1, 2, 3, 4]);

  const [isLoading, setIsloading] = useState(false);

  const {
    title,
    quote,
    warm_up,
    stretch,
    fundamentals,
    users_permissions_users,
    rounds: roundsData,
    slug,
  } = attributes;
  // console.log("ATTRIBUTES | ", attributes)
  // Const [counter, setCounter] = useState(1)
  console.log(attributes, "asasas");
  const navigate = useNavigate();
  const initialState = {
    title,
    quote,
    warm_up: warm_up.map((item) => ({
      move: item.move,
      seconds: item.seconds,
    })),
    stretch: stretch.map((item) => ({
      move: item.move,
      seconds: item.seconds,
    })),
    fundamentals: fundamentals.map((item) => ({
      move: item.move,
      seconds: item.seconds,
      img_src: item.img_src,
    })),
    slug,
    switch: attributes.switch,
    prepare_to_start: attributes.prepare_to_start,
    prepare_to_stretch: attributes.prepare_to_stretch,
    thankyou: attributes.thankyou,
    users_permissions_users,
    rounds: roundsData.map((item, i) => ({
      round_number: item.round_number,
      sections: item.sections.map((section, index) => {
        return {
          ...section,
        };
      }),
    })),
  };
  const [data, setData] = useState(initialState);

  function handleDataChange(value, key, props) {
    const { subSecIndex, round, index, formik, remove } = props;
    const { values, setValues } = formik;
    if (remove) {
      switch (key) {
        case "round": {
          const updatedRounds = [...data.rounds];
          updatedRounds.splice(index, 1);
          setData({ ...data, rounds: updatedRounds });

          const newFormValues = { ...formik.values, rounds: updatedRounds };
          setValues(newFormValues);

          break;
        }
        case "section": {
          const updatedSections = [...data.rounds[round].sections];
          updatedSections.splice(index, 1);
          setData((prevData) => ({
            ...prevData,
            rounds: [
              ...prevData.rounds.slice(0, round),
              {
                ...prevData.rounds[round],
                sections: updatedSections,
              },
              ...prevData.rounds.slice(round + 1),
            ],
          }));

          const updatedFormValues = { ...formik.values };
          updatedFormValues.rounds[round].sections.splice(index, 1);
          setValues(updatedFormValues);
          break;
        }
        case "subsection": {
          const updatedSections = { ...data.rounds[round].sections[index] };
          removeAndShift(updatedSections, subSecIndex);
          data.rounds[round].sections[index] = updatedSections;
          setData(data);
          const newFormValues = { ...formik.values };
          removeAndShift(newFormValues, subSecIndex);
          setValues(newFormValues);

          break;
        }

        case "active_recovery": {
          const updatedSections = [...data.rounds[round].sections];
          delete updatedSections[index].active_recovery;
          setData((prevData) => ({
            ...prevData,
            rounds: [
              ...prevData.rounds.slice(0, round),
              {
                ...prevData.rounds[round],
                sections: updatedSections,
              },
              ...prevData.rounds.slice(round + 1),
            ],
          }));

          const updatedFormValues = { ...formik.values };
          delete updatedFormValues.rounds[round].sections[index]
            .active_recovery;
          setValues(updatedFormValues);

          break;
        }
        default: {
          const updatedData = [...values[key]];
          updatedData.splice(index, 1);
          setData({ ...data, [key]: updatedData });

          const newFormValues = { ...formik.values, [key]: updatedData };
          setValues(newFormValues);

          break;
        }
      }
    } else {
      switch (key) {
        case "round": {
          const updatedRounds = [...values.rounds];
          updatedRounds.splice(index + 1, 0, value);
          setData({ ...data, rounds: updatedRounds });

          const newFormValues = { ...formik.values, rounds: updatedRounds };
          setValues(newFormValues);

          break;
        }
        case "section": {
          const updatedRounds = values.rounds.map((r, i) => {
            if (i === round) {
              const updatedSections = [...r.sections];
              updatedSections.splice(index + 1, 0, value);
              return { ...r, sections: updatedSections };
            }
            return r;
          });
          setData({ ...data, rounds: updatedRounds });

          // Reset Formik form values and field structure
          const newFormValues = { ...formik.values, rounds: updatedRounds };
          setValues(newFormValues);

          break;
        }
        case "subsection": {
          const updatedSections = { ...data.rounds[round].sections[index] };
          addAndShift(updatedSections, subSecIndex);
          console.log(updatedSections, "dataval", "add");
          data.rounds[round].sections[index] = updatedSections;
          setData(data);
          const newFormValues = { ...formik.values };
          addAndShift(newFormValues, subSecIndex);
          setValues(newFormValues);
          break;
        }
        case "active_recovery": {
          const updatedSections = [...data.rounds[round].sections];
          updatedSections[index].active_recovery = "Activity Recovery";
          setData((prevData) => ({
            ...prevData,
            rounds: [
              ...prevData.rounds.slice(0, round),
              {
                ...prevData.rounds[round],
                sections: updatedSections,
              },
              ...prevData.rounds.slice(round + 1),
            ],
          }));

          const updatedFormValues = { ...formik.values };
          updatedFormValues.rounds[round].sections[index].active_recovery =
            "Activity Recovery";
          setValues(updatedFormValues);

          break;
        }

        default:
          {
            const updatedData = [...values[key]];
            updatedData.splice(index + 1, 0, value);
            setData({ ...data, [key]: updatedData });

            const newFormValues = { ...formik.values, [key]: updatedData };
            setValues(newFormValues);
          }
          break;
      }
    }
  }
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission on Enter key press
    }
  };
  return (
    <div>
      <Formik
        initialValues={data}
        validationSchema={SessionCreationSquema}
        validateOnMount={true}
        onSubmit={async (values) => {
          // SetSubmitting(false)
          values.duration = calculateTime(values);
          values.rounds = values?.rounds?.map((item, index) => {
            return { ...item, round_number: index + 1 };
          });
          setIsloading(true);
          const response = await updateSessionContent(
            values,
            sessionId,
            userToken
          );
          setIsloading(false);

          if (response.data?.response?.data?.error?.status) {
            // // console.log("RESPONSE | Handle error: ", response.data.response.data.error.status, response.data.response.data.error.message)
          } else {
            // // console.log("RESPONSE | response", response)
            navigate("/");
          }
        }}
      >
        {({ handleSubmit, handleChange, values, errors, touched }) => (
          <Form onKeyPress={handleKeyPress} autoComplete="off">
            <div className="">
              <div className=" row">
                <div className="col-12 col-sm-6">
                  <FieldFormInput
                    label={"Title"}
                    name={nameFields.title}
                    type="text"
                    errors={errors}
                    touched={touched}
                    maxLength={20}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <FieldFormInput
                    textarea
                    label={"Quote"}
                    name={nameFields.quote}
                    type="text"
                    errors={errors}
                    touched={touched}
                  />
                </div>
              </div>
              <div className="row ">
                <div className=" col-12 col-sm-6">
                  <SectionDynamicForm
                    className=""
                    handleDataChange={handleDataChange}
                    name={nameFields.warmup}
                    col12
                  />
                </div>
                <div className=" col-12 col-sm-6">
                  <SectionDynamicForm
                    handleDataChange={handleDataChange}
                    name={nameFields.fundamentals}
                    col12
                  />
                </div>
              </div>

              <div className=" col-12 mt-4   text-center">
                {console.log(data)}
                {data.rounds.length > 0 ? (
                  <div className=" row mt-3   justify-content-center">
                    {data?.rounds.map((item, roundNumber) => (
                      <SectionBoxForm
                        key={roundNumber}
                        roundNumber={roundNumber}
                        round={item}
                        handleDataChange={handleDataChange}
                        data={data}
                        setData={setData}
                        schemaType={
                          data?.rounds?.length === 3
                            ? "Fundamentals"
                            : data?.fundamentals?.length === 4
                            ? "Hero"
                            : "OG"
                        }
                      />
                    ))}
                  </div>
                ) : null}
              </div>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <SectionDynamicForm
                    name={nameFields.switch}
                    col12
                    secHidden
                  />
                  <SectionDynamicForm
                    name={nameFields.prepareToStart}
                    col12
                    secHidden
                  />
                  <SectionDynamicForm
                    name={nameFields.prepareToStretch}
                    col12
                    secHidden
                  />
                  <SectionDynamicForm
                    name={nameFields.thankyou}
                    col12
                    secHidden
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <SectionDynamicForm name={nameFields.stretch} col12 />
                </div>
              </div>
              <div className="col-12 text-center mt-5">
                <Button
                  type={"submit"}
                  label={"Update Session"}
                  loading={isLoading}
                  onClick={handleSubmit}
                />
                <span style={{ paddingLeft: "10px" }}></span>
                <Button
                  label={"Cancel"}
                  type={"reset"}
                  onClick={() => {
                    localStorage?.removeItem("mysessionData");
                    navigate("/");
                  }}
                  // disabled={Object.keys(errors).length > 0}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SessionEditionForm;

export const nameFields = {
  warmup: "warm_up",
  title: "title",
  stretch: "stretch",
  quote: "quote",
  fundamentals: "fundamentals",
  switch: "switch",
  prepareToStart: "prepare_to_start",
  prepareToStretch: "prepare_to_stretch",
  thankyou: "thankyou",
};
