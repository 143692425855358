/* eslint-disable complexity */

import { useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";
import Typography from "../../reusable/typography/Typography";
import FieldFormDropDown from "../fields/FieldFormDropDown";
import { endpoint, getDataSelect } from "../fields/selectDataOptions";
import UploadModal from "../fields/UploadModal";
import { nameFields } from "../SessionCreationForm";
import AdminButton from "../../mui/AdminButton";
import FieldEditDropdown from "../../mui/FieldEditDropdown";

const SectionDynamicForm = ({ name, col12, secHidden, handleDataChange }) => {
  const formik = useFormikContext();
  const items = formik?.values[name];
  const [counter, setCounter] = useState(3);
  // console.log("Errors", formik.errors, formik.values, items, name);

  // Const handlePlusClick = () => {
  // 	const _items = [...items, {move: "Choose warm up"}]
  // 	formik.setFieldValue(name, _items)
  // }

  // const handleDelete = (item:any) => {
  // 	const _items = [...items]
  // 	_items.splice(_items.indexOf(item), 1)
  // 	formik.setFieldValue(name, _items)
  // }
 
  return (
    <div className={`${col12 ? "col-12" : "col-6"}  `}>
      <div className="border-1 border-dark my-3 rounded">
        <Typography semanticTag="p" variant="bodyLgBold">
          {name === nameFields.stretch
            ? "Stretch"
            : name === nameFields.fundamentals
            ? "Fundamentals"
            : name === nameFields.switch
            ? "Switch"
            : name === nameFields.prepareToStart
            ? "Prepare to start"
            : name === nameFields.prepareToStretch
            ? "Prepare to stretch"
            : name === nameFields.thankyou
            ? "Thank you screen"
            : "Warm up"}
        </Typography>

        {name === nameFields.switch ||
        name === nameFields.prepareToStart ||
        name === nameFields.prepareToStretch ||
        name === nameFields.thankyou ? (
          <SectionFieldGroup
            key={formik.values.title}
            index={0}
            secHidden={true}
            // HandleDelete={handleDelete}
            name={name}
          />
        ) : items?.length > 0 ? (
          <>
            {items.map((item, index) => (
              <SectionFieldGroup
                key={index}
                index={index}
                item={item}
                handleDataChange={handleDataChange}
                // HandleDelete={handleDelete}
                name={name}
              />
            ))}
          </>
        ) : null}

        {/* <div className="text-center mt-3">
					<PlusCircleFill size={24} className={"pointer"} onClick={handlePlusClick} />
				</div> */}
      </div>
    </div>
  );
};

export default SectionDynamicForm;

const SectionFieldGroup = ({
  handleDelete,
  section,
  handleDataChange,
  index,
  item,
  name,
  secHidden,
}) => {
  // Const [index, setIndex] = useState<number>(section)
  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const [warmUpOptionst, setWarmUpOptions] = useState(null);
  const formik = useFormikContext();

  useEffect(() => {
    // Formik.setFieldValue(`${name}[${index}].move`, name === nameFields.stretch ? "Choose stretch" : "Choose warm up")
    if (name === nameFields.warmup || name === nameFields.stretch) {
      getDataSelect(endpoint[name]).then((response) => {
        setWarmUpOptions(response);
      
      });
    }
  }, []);
  // console.log(index, item, name);

  function onDelete() {
    handleDataChange(item, name, { index, formik, remove: true });
  }
  function onDuplicate() {
    handleDataChange(item, name, { index, formik });
  }
  const {errors,touched}=formik
   useEffect(()=>{



      try {
        
        if(errors&& touched&&eval(`errors.${name}[${index}]`) &&eval(`touched.${name}[${index}]`)  !== undefined )
        {
         setIsToggleOpen(true)
       }
      } catch (error) {
        
      }
    },[errors,touched])
    const inputRefs = useRef([]);
    const handleKeyPress = (index) => () => {
      const nextIndex =  index + 1;
      inputRefs.current[nextIndex].focus();
    };
  return (
    <>
      {isToggleOpen ? (
        <div className=" row text-start">
          <div className="col-12">
            <div onClick={() => setIsToggleOpen(false)}
              className={`d-flex pointer justify-content-between align-items-top  border-bottom border-top border-dark border-1 py-3 px-3 mb-3 ${
                isToggleOpen ? "bg-dark rounded" : ""
              }`}
            >
              <div  >
                <Typography
                  className={`mb-0 ${isToggleOpen ? "bg-dark" : ""}`}
                  semanticTag="p"
                  variant="bodyLg"
                >
                  {name === nameFields.switch
                    ? "Switch"
                    : name === nameFields.prepareToStart
                    ? "Prepare to start"
                    : name === nameFields.prepareToStretch
                    ? "Prepare to stretch"
                    : name === nameFields.thankyou
                    ? "Thank you screen"
                    : formik?.values?.[name][index]?.move}
                </Typography>
              </div>

              <div
                className={` d-flex align-items-center ${
                  isToggleOpen ? "bg-dark rounded " : ""
                }`}
              >
                <div>
                  <Typography semanticTag="span" variant={"bodySm"}>
                    {name === nameFields.switch
                      ? "90sec"
                      : name === nameFields.switch
                      ? "60sec"
                      : secHidden
                      ? ""
                      : formik?.values?.[name][index]?.seconds}
                  </Typography>
                  <CaretUpFill
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsToggleOpen(false)}}
                    size={18}
                    className={` pointer text-end me-4 ${
                      isToggleOpen ? "bg-dark" : ""
                    }`}
                  />
                </div>

                {(name === nameFields.warmup ||
                  name === nameFields.stretch ||
                  name === nameFields.fundamentals) && (
                  <FieldEditDropdown
                    onClick={() => {
                      setIsToggleOpen(false);
                    }}
                    onDelete={onDelete}
                    onDuplicate={onDuplicate}
                  />
                )}
              </div>
            </div>
          </div>

          {/* <Typography className="col-12 text-center" semanticTag="p" variant="bodyLg">{"Floor - Bag: Group 1"}</Typography> */}

          <div className="col-12">
            {name === nameFields.switch ||
            name === nameFields.prepareToStart ||
            name === nameFields.prepareToStretch ||
            name === nameFields.thankyou ? (
              <div className="mt-2 mb-3">
                {/* <FieldFormDropDown typeValue="move" label="Bag 1" name={`${name}[${index}].move`} options={warmUpOptionst} index={index} round={0} section={0} fieldName={name}/> */}
                <UploadModal
                  fieldName={
                    name === nameFields.fundamentals
                      ? `${name}[${index}].img_src`
                      : name
                  }
                />
                <img
                  src={
                    name === nameFields.fundamentals
                      ? formik.values.fundamentals[index].img_src
                      : formik?.values[name]
                  }
                  width={100}
                  height={100}
                  className={"ms-5"}
                />
              </div>
            ) : (
              <div>
                <FieldFormDropDown 
               
                  typeValue="move"
                  label="move"
                  name={`${name}[${index}].move`}
                  options={warmUpOptionst}
                  index={index}
                  round={0}
                  section={0}
                  fieldName={name}
                  inputProps={{ onKeyPress: handleKeyPress(1) }}
                  inputRef={(ref) => (inputRefs.current[1] = ref)}
                />
                <FieldFormDropDown 
                  type={"number"}
                  typeValue="roundSection"
                  label="time"
                  name={`${name}[${index}].seconds`}
                  index={index}
                  round={0}
                  section={0}
                  fieldName={name}
                  min={0}
                  max={500}
                  inputProps={{ onKeyPress: handleKeyPress(2) }}
            inputRef={(ref) => (inputRefs.current[2] = ref)}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className=" row justify-content-center text-start">
          <div className="col-12">
            <div onClick={() => setIsToggleOpen(true)} className=" d-flex pointer justify-content-between align-items-top border-bottom border-top border-dark border-1 py-3 px-3">
              <div className="" >
                <Typography className={" mb-0"} semanticTag="p" variant="bodyLg">
                  {name === nameFields.switch
                    ? "Switch"
                    : name === nameFields.prepareToStart
                    ? "Prepare to start"
                    : name === nameFields.prepareToStretch
                    ? "Prepare to stretch"
                    : name === nameFields.thankyou
                    ? "Thank you screen"
                    : formik?.values?.[name][index]?.move}
                </Typography>
              </div>
              <div
                className={`d-flex align-items-center ${
                  isToggleOpen ? "bg-dark rounded " : ""
                }`}
              >
                <div>
                  <Typography semanticTag="span" variant={"bodySm"}>
                    {name === nameFields.switch
                      ? "90sec"
                      : secHidden
                      ? ""
                      : formik?.values?.[name][index]?.seconds}
                  </Typography>
                  <CaretDownFill
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsToggleOpen(true)}}
                    size={18}
                    className={" pointer text-end me-4"}
                  />
                </div>
                {(name === nameFields.warmup ||
                  name === nameFields.stretch ||
                  name === nameFields.fundamentals) && (
                  <FieldEditDropdown
                    onClick={() => {
                      setIsToggleOpen(false);
                    }}
                    onDelete={onDelete}
                    onDuplicate={onDuplicate}
                  />
                )}
                {/* <TrashFill size={18} className="pointer" onClick={() => handleDelete(item)}/> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
