import React, { useContext } from "react";
import UserDataContext from "../../context/UserDataContext";
import { FloorComponent } from "../screenPlay/screens/WorkoutScreen";
import BoxSacks from "./BoxSacks";

const NextScreenSectionDouble = ({
    nextBag,
    nextFloor,
    nextRound,
    nextActiveRecovery,
}) => (
    <div
        className=" position-absolute  start-50 translate-middle-x w-100 next-screen-container"
        style={{
            height: "189px",
            bottom: "24px",
            background: "rgba(225,225,225,0.1)",
            backdropFilter: "blur(8px)",
            border: "1px solid rgba(225,225,225,0.3)",
            borderRadius: "20px",
            boxShadow: "inset 0 0 50px 50px rgba(225,225,225,0.04)",
        }}
    >
        <div className="row g-0 ">
            <div className=" rounded col-sm-6 pe-5 p-3">
                <div className="">
                    {nextBag ? (
                        <BoxSacks
                            next
                            className=""
                            label={"next bag"}
                            boxing={nextBag}
                            col2
                        />
                    ) : nextActiveRecovery ? (
                        <BoxSacks
                            next
                            label={nextActiveRecovery.label}
                            boxing={nextActiveRecovery.move}
                            col2
                        />
                    ) : null}
                </div>
            </div>
            <div className={`col-sm-6 ${nextFloor}  `}>
                <div className="row nested-floor ps-4 me-1 ">
                    <FloorComponent
                        label={"next floor"}
                        nextRound={nextRound}
                        floor={nextFloor}
                        col2
                    />
                </div>
            </div>
        </div>
    </div>
);

export default NextScreenSectionDouble;

export const NextScreenSectionDynamic = ({ nextScreen }) => {
    const { showNextScreen } = useContext(UserDataContext);
    return (
        <>
            {nextScreen?.bag && nextScreen?.floor && showNextScreen ? (
                <NextScreenSectionDouble
                    nextBag={nextScreen.bag}
                    nextFloor={nextScreen.floor}
                    nextRound={nextScreen.round}
                />
            ) : nextScreen?.label && nextScreen?.move && showNextScreen ? (
                <div
                    className=" next-text position-absolute  start-50 translate-middle-x w-100 "
                    style={{
                        height: "189px",
                        bottom: "24px",
                        background: "rgba(225,225,225,0.1)",
                        backdropFilter: "blur(8px)",
                        border: "1px solid rgba(225,225,225,0.3)",
                        borderRadius: "20px",
                        boxShadow: "inset 0 0 50px 50px rgba(225,225,225,0.04)",
                    }}
                >
                    <div className=" row justify-content-end g-0">
                        <div className="col-8 col-sm-6 pe-2">
                            {nextScreen.label && nextScreen.move ? (
                                <FloorComponent
                                    label={nextScreen.label}
                                    nextRound={nextScreen.round}
                                    floor={nextScreen.move}
                                    col2
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};
