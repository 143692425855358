import React, { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import NavBar from "./reusable/NavBar";
import { Typography } from "@mui/material";
import UserDataContext from "../context/UserDataContext";

const Layout = () => {
    const { isLoading, userData } = useContext(UserDataContext);
    const [current, setCurrent] = useState();
    const [height, setHeight] = useState();
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            {isLoading ? (
                <div className="text-center pt-2 ">
                    <Typography variant="bodyLgBold" semanticTag="h1">
                        Loading...
                    </Typography>
                </div>
            ) : (
                <>
                    <NavBar />
                    <div style={{ flex: 1 }}>
                        {/* {current && (
  <div className="overlay" style={{top:height?.current?.height}}>
    <img src={current.image} alt={current.label} />
  </div>
)} */}

                        <Outlet context={{ current, setCurrent, setHeight }} />
                    </div>
                    <div
                        className="mt-3 py-1 text-center rounded"
                        style={{
                            backgroundColor: "#02111E",
                        }}
                    >
                        <img
                            src="/lp-logo.png"
                            alt="Lucky Punch Logo"
                            width={100}
                            height={50}
                        />
                        <p className="mt-2 ">
                            Developed And Maintain by{" "}
                            <a
                                href="https://www.fabtechsol.com"
                                target="_blank"
                            >
                                Fabtechsol
                            </a>
                        </p>
                    </div>
                </>
            )}
        </div>
    );
};

export default Layout;
