import React, { useContext } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';


import EditIcon from '@mui/icons-material/Edit';
import UserDataContext from '../../context/UserDataContext';

const FieldEditDropdown = ({editText,onDelete,onDuplicate,onClick=()=>{}}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
   try {
    
     event.stopPropogation()
   } catch (error) {
    
   }
    setAnchorEl(event.currentTarget);
    onClick()
  };

  const handleClose = (e) => {
    e?.stopPropagation()
    setAnchorEl(null);
  };

  const handleDelete = () => {
    // Handle delete action here
    onDelete()
    handleClose();
  };

  const handleDuplicate = () => {
    // Handle duplicate action here
    onDuplicate()
    handleClose();
  };
return (
    <div className=''>
      <IconButton onClick={(e)=>{
        e.stopPropagation()
        handleClick(e)}}>
        <MoreVertIcon sx={{color:"white"}} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
  {onDelete&&      <MenuItem onClick={handleDelete}>
          <DeleteIcon />
          Delete
        </MenuItem>}
   {onDuplicate&&     <MenuItem onClick={handleDuplicate}>
          <FileCopyIcon />
        {editText||  "Duplicate"}
        </MenuItem>}
      </Menu>
    </div>
  );
};

export default FieldEditDropdown;


export const SessionOptionDropdown = ({ onDelete, onDuplicate, onEdit }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    try {
      
      event.stopPropagation()
    } catch (error) {
      
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onDelete();
    handleClose();
  };

  const handleDuplicate = () => {
    onDuplicate();
    handleClose();
  };

  const handleEdit = () => {
    onEdit();
    handleClose();
  };

  return (
    <div>
      <IconButton onClick={handleClick} className=''>
        <MoreVertIcon sx={{ color: 'white' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDelete}>
          <DeleteIcon />
          Delete
        </MenuItem>
        <MenuItem onClick={handleDuplicate}>
          <FileCopyIcon />
          Duplicate
        </MenuItem>
        <MenuItem onClick={handleEdit}>
          <EditIcon />
          Edit
        </MenuItem>
      </Menu>
    </div>
  );
};

