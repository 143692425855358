import SessionTraningCollection from "../components/sessionsCollection/SessionTraningCollection";

function HomePage() {
  return (
    <div className="" style={{ minHeight: "75vh" }}>
      <SessionTraningCollection />
    </div>
  );
}

export default HomePage;
