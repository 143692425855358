/* eslint-disable camelcase */
import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import CounterDown from "../../reusable/CounterDown";
import { NextScreenSectionDynamic } from "../../reusable/NextScreenSection";
import Typography from "../../reusable/typography/Typography";
import SectionRound from "../../reusable/SectionRound";
import { Height } from "@mui/icons-material";
// Const ReusableScreensRow = ({values, initial}) => {
// 	const [counter, setCounter] = useState<number>(initial)

// 	return ({values.map((value, index) => {
// 				// console.log("Stretch Index: ", initial + index)
// 				return <Screen key={`${initial + index}`} value={value.move}/>
// 			})})

// }

const ReusableScreensRow = ({
    value,
    label,
    img_src,
    seconds,
    isStoped,
    nextMove,
    data,
}) => {
    useEffect(() => {
        const viewportHeight = window.innerHeight;
        console.log(viewportHeight);
    });
    return (
        <div
            style={{ height: "93vh", marginTop: "3%", padding: "0 15px " }}
            className=" w-100  text-red text-center"
        >
            <div
                className=" row align-items-top  "
                style={{
                    height: "54vh",
                    background: "rgba(225,225,225,0.1)",
                    backdropFilter: "blur(8px)",
                    border: "1px solid rgba(225,225,225,0.3)",
                    borderRadius: "20px",
                    boxShadow: "inset 0 0 50px 50px rgba(225,225,225,0.04)",
                }}
            >
                {/* <div className=" col-12 playtext-label "> */}
                <Typography
                    style={
                        {
                            // fontSize: "5rem",
                            // fontWeight: "200",
                            // paddingBottom: "0.5rem", // adjust this as needed
                            // marginBottom: "0px !important", // adjust this as needed
                        }
                    }
                    //   semanticTag="h1"
                    //   variant="heading1"
                    // className="green-border-bottom border-red border-5 m-auto"
                >
                    {/* {label.toUpperCase()} */}
                </Typography>
                {/* </div> */}
                {img_src && (
                    <div className="col-12 d-flex justify-content-center align-items-center">
                        <img
                            src={img_src}
                            style={{
                                width: "350px",
                                height: "350px",
                            }}
                        />
                    </div>
                )}

                <div className=" col-12 playtext-value ">
                    <Typography
                        style={{
                            fontSize: img_src ? "10rem" : "10rem",
                            fontWeight: "200",
                        }}
                        semanticTag="h1"
                        variant="heading1"
                    >
                        {value.toUpperCase()}
                    </Typography>
                </div>

                {/* <div className="col-12">
		<SectionRound round={data?.round} section={data?.section}/>
		</div> */}
            </div>
            <div className="col-12  ">
                {seconds && (
                    <CounterDown
                        totalSeconds={seconds}
                        isStoped={isStoped}
                        absolute={true}
                    />
                )}
            </div>
            <NextScreenSectionDynamic nextScreen={nextMove} bgRed data />
        </div>
    );
};
export default ReusableScreensRow;
