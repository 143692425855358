import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllUsers } from "../utility/fetch/fetchSessions";
import { Box, Paper } from "@mui/material";
import TableMui from "../components/mui/TableMui";
import AdminButton from "../components/mui/AdminButton";
import userAuth from "../utility/auth/authProvider";
import Button from "../components/reusable/Button";

const UsersList = () => {
  const navigate=useNavigate()
const [data,setData]=useState()
  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    const result = await getAllUsers(userAuth.getUserToken());
   setData(  result?.data);

  }
  const blogDetail=(item)=>{
  
  
navigate(`/user/${item.id}/history`)
    }
  return <div>

<Box component={Paper} sx={{ marginBottom: "20px", padding: "20px" }}>
 

      

          <Box>
            <TableMui
              styleTableTh={{ fontWeight: "bold", whiteSpace: "nowrap" }}
             showId
              th={{
                    id: "id",
                    username:"Username",
                    email:"Email",
                    records:"Session Records",
                    detail:"Action"
              }}
              td={data}
              customFields={[
                {name:"detail",data:(value,item)=>(
                    <Button  onClick={()=>blogDetail(item)}
                    label="Detail"


                  />
              )},
        
         
            
              
              ]}
            />

          </Box>
          </Box>
  </div>;
};

export default UsersList;
