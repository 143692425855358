import React, {useContext} from "react"
import {Link} from "react-router-dom"
import UserDataContext from "../../context/UserDataContext"
import userAuth from "../../utility/auth/authProvider"
import Cookies from "../../utility/auth/Cookie"
import Button from "./Button"
import Typography from "./typography/Typography"



const UserCardLg = ({username, picture}) => {
	const {logOut,isAdmin} = useContext(UserDataContext)
	const alt = picture?.alternativeText
	const src = `${picture?.formats?.thumbnail?.url}`

	return (
	<nav class=" navbar navbar-expand-md rounded px-3 mt-3" style={{backgroundColor:'#171717'}}>
	<button class="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent">
		<span class="navbar-toggler-icon"></span>
	</button>
	
	<div class=" collapse navbar-collapse" id="navbarContent">
		<div class=" w-100 row align-items-center justify-content-start text-start text-md-start pt-3">
			<div class="col-3 col-md-2">
				<Link to={"/"}>
					<img src={src} alt={alt} class="border-radius-5 thumbnail" />
				</Link>
			</div>
			<div class=" col-4 col-md-4">
				<Typography semanticTag="h2" variant="bodyLgBold">
					{username}
				</Typography>
				<Typography semanticTag="h2" variant="bodySm">
					{isAdmin?"Head Coach":"Trainer"}
				</Typography>
				<div onClick={() => logOut()} class="pointer">
					<Typography semanticTag="h2" variant="bodySm">
						{"Log out"}
					</Typography>
				</div>
			</div>
			<div class="col-5 col-md-4 text-end text-md-start">
				<Button className="" label="All Sessions" href="/" />
			</div>
			<div class=" col-auto col-md-1 text-end d-none d-md-block">
				<Link to={"/"}>
					<img src="/lp-logo.png" alt="Lucky Punch Logo" width={100} height={50} />
				</Link>
			</div>
		</div>
	</div>
</nav>

	)
}

export default UserCardLg
