import { CircularProgress } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

var Color;
(function (Color) {
  Color["primary"] = "primary";
  Color["dark"] = "dark";
  Color["darkBlue"] = "darkBlue";
  Color["secondary"] = "secondary";
  Color["danger"] = "danger";
  Color["light"] = "light";
  Color["red"] = "red";
  Color["black"] = "black";
  Color["white"] = "white";
})(Color || (Color = {}));

const Button = ({
  disabled = false,
  type,
  label,
  onClick,
  loading,
  href,
  bgColor = "secondary",
  color,
  textColor = "white",
  className,
  outline = false,
}) => {
  const originalClassGroup = `${className ? className : ""} py-1 px-3 btn ${
    bgColor ? `bg-${bgColor}` : ""
  } ${textColor ? `text-${textColor}` : "text-white"} ${
    outline ? "border rounded" : ""
  } ${color ? `border-${color}` : ""}`;
  const [classGroup, setClassGroup] = React.useState(originalClassGroup);
  // Const classGroup = className

  return (
    <>
      {href ? (
        <Link to={href} className={classGroup}>
          {label}
        </Link>
      ) : (
        <button
          disabled={disabled}
          type={type && type}
          onClick={onClick ? onClick : undefined}
          className={classGroup}
        >
          {loading ? <CircularProgress color="inherit" size={10} /> : null}{" "}
          {label}
        </button>
      )}
    </>
  );
};

export default Button;
