import React, { useContext, useEffect, useState } from "react";
import Button from "../reusable/Button";
import Typography from "../reusable/typography/Typography";
import SessionTrainingCollectionItem from "./SessionTrainingCollectionItem";
import UserDataContext from "../../context/UserDataContext";
import { getSessionsData } from "../../utility/fetch/fetchSessions";
import { CircularProgress, Container, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import userAuth from "../../utility/auth/authProvider";

const SessionTraningCollection = ({ sessions }) => {
  const { isAdmin } = useContext(UserDataContext);
  const userAuthData = userAuth.getUserProfile();
  const [data, setData] = useState();
  const [search, setSearch] = useState();
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    fetchData(search);
  }, [search]);
  async function fetchData(search) {
    setIsloading(true);
    const result = await getSessionsData(userAuthData, search);
    setData(result?.data?.data);
    setIsloading(false);
    // console.log(result.data);
    // console.log(result?.data?.data);
  }

  return (
    <>
      <div className=" row py-3">
        <div className="col-12">
          <Typography variant="bodySmBold" semanticTag="h2">
            Sessions
          </Typography>

          <div className="d-flex align-items-center justify-content-center  gap-3">
            <Button
              className="white_space_2"
              href={"/createSession"}
              label={"Create Session"}
            />
            {isAdmin && (
              <Button
                className="white_space_2"
                href={"/session-schema"}
                label={"Session Schema"}
              />
            )}
            {isAdmin && <Button href={"/users"} label={"Trainers"} />}
            <div className="col-3">
              <div class="input-group">
                <input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  type="search"
                  class="form-control  shadow-none "
                  style={{ backgroundColor: "#6C757D", borderColor: "#6C757D" }}
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="search-icon"
                />
                <div class="input-group-append" style={{}}>
                  <span
                    class="input-group-text "
                    id="search-icon"
                    style={{
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0",
                      backgroundColor: "#6C757D",
                      color: "white",
                      borderColor: "#6C757D",
                    }}
                  >
                    <SearchIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isLoading ? (
          <div
            className="row g-12"
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "30px 0",
            }}
          >
            <CircularProgress size={80} />
          </div>
        ) : null}

        <div className=" col-12 mt-5">
          <div className="row g-4 ">
            {data?.map((session) => {
              const { id } = session;
              return (
                <div key={id} className=" col-12 col-md-4 mt-0">
                  <SessionTrainingCollectionItem
                    fetchData={fetchData}
                    session={session}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SessionTraningCollection;
