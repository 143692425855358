import { ErrorMessage, Field, useFormikContext } from "formik";

const FieldFormInput = ({
  classNameField,
  classNameContainer,
  name,
  onBlur,
  onKeyUp,
  onKeyDown,
  onChange,
  disabled,
  textarea,
  label,
  type = "text",
  color = "white",
  ...otherProps
}) => {
  const { handleChange, errors, touched, setFieldTouched } = useFormikContext();
  const classFormik = `input-transparent text-${color} w-100`;
  const classContainer = `${classNameContainer || ""} position-relative`;

  const classField = `${classNameField || ""} ${
    errors[name] && touched[name] !== undefined ? "border border-danger" : "border"
  } rounded mt-1 mb-2 px-1 py-1 w-100`;

  return (
    <div className={classContainer}>
      {/* <label htmlFor={name}
				className={`text-${color} `}
				style={{
					marginTop: "-25px",
				}}
			>
				<Typography variant="bodySmBold" semanticTag="span">{label}</Typography>
			</label> */}

      <div className={classField}>
        {textarea ? (
          <>
            <Field
              component="textarea"
              rows={4}
              onChange={handleChange(name)}
              name={name}
              onBlur={() => setFieldTouched(name)}
              onKeyUp={onKeyUp}
              onKeyDown={onKeyDown}
              disabled={disabled}
              className={classFormik}
              placeholder={label}
              {...otherProps}
            />
          </>
        ) : (
          <>
            <Field
              className={classFormik}
              name={name}
              type={type}
              onBlur={() => setFieldTouched(name)}
              // onKeyUp={onKeyUp && onKeyUp}
              // onKeyDown={onKeyDown && onKeyDown}
              onChange={handleChange(name)}
              placeholder={label}
              {...otherProps}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default FieldFormInput;
