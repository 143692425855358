import { Typography, Drawer, Box, Paper } from "@mui/material";
import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { BsArrowsFullscreen } from "react-icons/bs";

export default ({ values }) => {
  const { current, show, setShow } = values;
  const [full, setFull] = useState(false);
  // console.log(current);
  const groupedData = current?.reduce((acc, item) => {
    let key=item.type=="round"?`round_${item.round}`:item.type
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});
  // console.log(groupedData,"cat");
  return (
    <Drawer
      anchor={"right"}
      sx={{
        display: { md: "block" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: {
            xs: "100%",
            md: full ? window.innerWidth : "60%",
          },
          zIndex: { md: 1100, xs: 1200 },
        },
      }}
      open={show}
      onClose={() => setShow(false)}
    >
      <div className="d-flex  align-items-center p-2">
        <RxCross2 size={35} onClick={() => setShow(false)} />
        <BsArrowsFullscreen size={25} onClick={() => setFull(!full)} />
      </div>
      <div style={{ maxWidth: "100%", maxHeight: "80vh", overflowX: "hidden" }}>
        <div className="d-flex justify-content-between px-2">
          <Typography variant="h5" sx={{ mt: 3 }}>
            Session Skip History
          </Typography>
        </div>
 
        <div className="container">
          {Object.entries(groupedData).map(([type, items]) => (
            <Box component={Paper} className="px-3">
              {type.startsWith("round") ? (
                <ExerciseRoundComponent key={type} type={type} data={items} />
              ) : (
                <ExerciseComponent key={type} type={type} data={items} />
              )}
            </Box>
          ))}
        </div>
      </div>
    </Drawer>
  );
};

const ExerciseComponent = ({ type, data }) => {
  if(type=="undefined")
 return
  return (
    <div>
      <h2>{type||""}</h2>
      {data.map((item,index) => (
        <div key={index}>
          <p>
            <strong>Move: </strong>
            {item.move}
          </p>
          <p>
            <strong>Seconds:</strong> {item.seconds}
          </p>
        </div>
      ))}
    </div>
  );
};
const ExerciseRoundComponent = ({ type, data }) => {
  const groupedData = data?.reduce((acc, item) => {
    let key=item.section?"section_"+item.section:"Switch Floor"
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});
  // console.log(groupedData,"sections");
  return (
    <div>
      <h2>{type}</h2>
      {Object.entries(groupedData).map(([type, items]) => (
        <>
         <Box component={Paper} className="px-2">
         <p><strong>{type} </strong></p>
    {  items.map((item,index) => (

          <div key={index}>
            <p><strong>Action: </strong>{item.move||item.floor||item.bag||item.label}</p>
            <p><strong>Seconds:</strong> {item.seconds}</p>
          </div>
        ))}
      </Box>
        </>
      )
      )}
    </div>
  );
};
