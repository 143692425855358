import { motion } from "framer-motion";
import React, { useContext } from "react";
import UserDataContext from "../../../context/UserDataContext";
import CounterDown from "../../reusable/CounterDown";
import { NextScreenSectionDynamic } from "../../reusable/NextScreenSection";
import Typography from "../../reusable/typography/Typography";

const WelcomeScreen2 = ({ quote, seconds, isStoped, nextMove }) => {
  const { userData } = useContext(UserDataContext);

  const url = userData[0].trainer_gif ? userData[0].trainer_gif.url : "";
  const alternativeText = userData[0].trainer_gif
    ? userData[0].trainer_gif.alternativeText
    : "";
  // // console.log("URL | ", url)
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="row align-items-center justify-content-center text-center vh-100">
        <div className="col-6 text-start">
          <Typography
            variant={"heading1"}
            semanticTag={"h1"}
            style={{
              fontSize: "100px",
              fontWeight: "200",
            }}
          >
            {"WELCOME"}
          </Typography>
          <Typography
            variant={"heading3"}
            semanticTag={"h2"}
            style={{
              fontSize: "50px",
              fontWeight: "200",
            }}
          >
            {quote?.toUpperCase()}
          </Typography>
        </div>
        <div className="col-6 text-center">
          <img src={url} alt={alternativeText} height={"auto"} width={"100%"} />
        </div>
      </div>
      {seconds && <CounterDown totalSeconds={seconds} isStoped={isStoped} />}
      <NextScreenSectionDynamic nextScreen={nextMove} bgRed />
    </motion.div>
  );
};

export default WelcomeScreen2;
