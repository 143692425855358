import {motion} from "framer-motion"
import React from "react"
import CounterDown from "../../reusable/CounterDown"
import {NextScreenSectionDynamic} from "../../reusable/NextScreenSection"
import Typography from "../../reusable/typography/Typography"

const WelcomeScreen2= ({seconds, isStoped, nextMove}) => (
	<motion.div
		initial={{opacity: 0}}
		animate={{opacity: 1}}
		exit={{opacity: 0}}>

		<div className="row align-items-center justify-content-center text-center vh-100">
			<div className="playtext-value col-12 text-center">
				<Typography variant={"heading1"} semanticTag={"h1"} style={{
					fontSize: "15rem",
					fontWeight: "200",
				}}>
					{"ARE YOU"} {"READY?"}
				</Typography>
			</div>
		</div>
		{seconds && <CounterDown totalSeconds={seconds} isStoped={isStoped}/>}
		<NextScreenSectionDynamic nextScreen={nextMove} bgRed />
	</motion.div>
)

export default WelcomeScreen2
