/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from "react";
import { Field, useFormikContext } from "formik";
import Typography from "../../reusable/typography/Typography";
import { useOutletContext } from "react-router-dom";
import FieldFormDropDownItem, {
  FieldFormDropDownCategory,
} from "./FieldFormDropDownItem";
const FieldFormDropDown = ({
  typeValue,
  index,
  nextName,
  section,
  fieldName,
  schemaType,
  round,
  color = "white",
  classNameField,
  classNameContainer,
  name,
  type,
  label,
  disabled,
  options,
  ...otherProps
}) => {
  const formik = useFormikContext();
  const { values, errors, touched, setFieldTouched } = formik;

  const [expandedCategory, setExpandedCategory] = useState(null);
  // Const [dropDownOptions, setDropDownOptions] = useState<any[]>(options)
  const [showDropDown, setShowDropDown] = useState(false);
  const { current, setHeight } = useOutletContext();
  const [isMouseOver, setIsMouseOver] = useState(false);
  // //// console.log(formik.values[name], formik.errors[name], "textarea", textarea)
  const classFormik = `input-transparent text-${color} w-100`;
  const classContainer = `${
    classNameContainer && classNameContainer
  } position-relative`;
  let classField;
  try {
    classField = `position-relative ${classNameField || ""} ${
      errors &&
      touched &&
      eval(`errors.${name}`) &&
      eval(`touched.${name}`) !== undefined
        ? "border border-danger"
        : "border"
    } rounded mt-1 mb-2 px-1 py-1 w-100`;
  } catch (error) {
    classField = `position-relative border
  rounded mt-1 mb-2 px-1 py-1 w-100`;
  }

  // console.log(name,touched,errors,classField,"error1.....................................................................");

  // console.log("error............\n...\n...\n....");

  const [categoryDropdown] = useState(
    options?.some((option) => "categoryName" in option) || false
  );

  const value =
    typeValue === "roundSection"
      ? values.rounds[round].sections[section][fieldName]
      : values[fieldName][index].move;

  const dropDownOptions = !value
    ? options
    : categoryDropdown
    ? filterSearchCategory(value, options)
    : filterSearch(value, options);
  const hasError = formik.touched[name] && formik.errors[name];
  // console.log("error",hasError,formik.errors);
  const onChange = () => {
    setShowDropDown(true);
    formik.setFieldValue(name, value);
  };

  const onKeyDown = (e) => {
    if (e.keyCode !== 9) {
      setShowDropDown(true);
    }
  };

  const [clickedItem, setClickedItem] = useState(false);

  // const onBlur = () => {
  //   // Wait for click event to fire before closing the dropdown
  // //   setTimeout(() => {
  // //  setShowDropDown(false);
  // //   }, 200);
  // }

  const onClickItem = () => {
    setClickedItem(true);
    setShowDropDown(false);
  };
  useEffect(() => {
    // Check if the type is "roundSection", the round is even, and there is a next round
    if (
      typeValue === "roundSection" &&
      round % 2 === 0 &&
      values?.[Number(round) + 1]?.sections?.[section]?.[fieldName]
    ) {
      formik.setFieldValue(
        `rounds.${Number(round) + 1}.sections.${section}.${fieldName}`,
        value
      );
    }
  }, [value]);

  // console.log(dropDownOptions,name,options,"dropdown");
  const dropDownRef = useRef(null);
  useEffect(() => {
    // Function to check if clicked outside of dropdown
    function handleClickOutside(event) {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setShowDropDown(false);
      }
    }

    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);
    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropDownRef]);
  return (
    <div className={classContainer}>
      <div className={classField} ref={dropDownRef}>
        <Field
          placeholder={label}
          className={classFormik}
          onChange={(e) => {
            if (schemaType) {
              const bag_pattern = /^bag_(\d+)$/;
              const bag_match = fieldName.match(bag_pattern);

              formik.setFieldValue(
                `rounds[${round}].sections[${section}].${fieldName}`,
                bag_match
                  ? e.target.value.replace(/\d+/g, (match) =>
                      match.split("").join("-")
                    )
                  : e.target.value
              );

              if (schemaType !== "Fundamentals" && round % 2 === 0) {
                formik.setFieldValue(
                  `rounds[${round + 1}].sections[${section}].${fieldName}`,
                  bag_match
                    ? e.target.value.replace(/\d+/g, (match) =>
                        match.split("").join("-")
                      )
                    : e.target.value
                );
              }
            } else {
              formik.setFieldValue(name, e.target.value);
            }
          }}
          onKeyDown={onKeyDown}
          name={name}
          type={type}
          {...otherProps}
        />
        {options && showDropDown && (
          // {dropDownOptions.length>0 && (
          <div>
            <div
              className={`position-absolute ${
                name.startsWith("warm_up") || name.startsWith("stretch")
                  ? "w-50"
                  : "w-100"
              } py-1 bg-dark overflow-auto`}
              style={{
                zIndex: 10,
                height: 500,
              }}
            >
              {dropDownOptions &&
                dropDownOptions.map((option, index) =>
                  categoryDropdown ? (
                    <FieldFormDropDownCategory
                      key={index}
                      categoryData={option}
                      option={option}
                      schemaType={schemaType}
                      onClickItem={onClickItem}
                      setShowDropDown={setShowDropDown}
                      expandedCategory={expandedCategory}
                      setExpandedCategory={setExpandedCategory}
                      name={name}
                      section={section}
                      fieldName={fieldName}
                      round={round}
                    />
                  ) : (
                    <FieldFormDropDownItem
                      key={index}
                      option={option}
                      onClickItem={onClickItem}
                      schemaType={schemaType}
                      setShowDropDown={setShowDropDown}
                      name={name}
                      section={section}
                      fieldName={fieldName}
                      round={round}
                    />
                  )
                )}
            </div>

            {/* {current?.image&& <div className="overlay mx-3" >
    <img style={{height:"inherit",objectFit:"cover"}} src={current.image} alt={current.label} />
  </div>} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default FieldFormDropDown;

const filterSearch = (search, options) => {
  return options
    ? options?.filter(
        (option) =>
          option.label?.toLowerCase().includes(search?.toLowerCase()) ||
          option.label
            ?.toLowerCase()
            .replaceAll("-", "")
            .includes(search?.toLowerCase())
      )
    : [];
};

const filterSearchCategory = (search, options) => {
  if (!options) return [];

  // Make search case-insensitive
  const searchLower = search.toLowerCase();

  // Filter options (categories)
  const filteredOptions = options.filter((option) => {
    const categoryNameMatches = option.categoryName
      .toLowerCase()
      .includes(searchLower);

    // Filter items within each category
    const filteredItems = option.itemsArray.filter(
      (item) =>
        item.label.toLowerCase().includes(searchLower) ||
        item.label?.toLowerCase().replaceAll("-", "").includes(searchLower)
    );

    // If category name matches or there are any matching items, return true
    return categoryNameMatches || filteredItems.length > 0;
  });

  // Return categories with filtered items
  return filteredOptions.map((option) => {
    return {
      ...option,
      itemsArray: option.itemsArray.filter(
        (item) =>
          item.label.toLowerCase().includes(searchLower) ||
          item.label?.toLowerCase().replaceAll("-", "").includes(searchLower)
      ),
    };
  });
};
