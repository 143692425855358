import { useFormikContext } from "formik"
import React, { useContext, useEffect, useState } from "react"
import { X } from "react-bootstrap-icons"
import UserDataContext from "../../../context/UserDataContext"
import Button from "../../reusable/Button"
import Typography from "../../reusable/typography/Typography"
import UploadFileButton from "./UploadFileButton"

const UploadFilesManager = ({ closeModal, fieldName }) => {
  const [selectedFile, setSelectedFile] = useState(null)
  const { files, filesLoading } = useContext(UserDataContext)

  const sortFiles = files.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))

  return (
    <div
      className={"bg-dark py-4 px-4 rounded"}
      style={{
        width: "800px"
      }}
    >
      <div className="d-flex pb-3 pt-2 justify-content-between align-items-center border-bottom border-1 border-white">
        <div className={"d-flex"}>
          <Typography semanticTag="h1" variant={"bodyLgBold"}>
            {"File Manager"}
          </Typography>
        </div>
        <X size={30} onClick={closeModal} className={"pointer"} />
      </div>
      <div className="row">
        {filesLoading ? (
          <div className={"text-center pt-2"}>
            <Typography variant="bodyLgBold" semanticTag="h1">
              Loading...
            </Typography>
          </div>
        ) : (
          <>
            <div className="col-8 py-3 ">
              <div
                className="row g-3 overflow-auto"
                style={{
                  height: "300px"
                }}
              >
                {files.map((file, index) => (
                  <FileItem
                    key={file.name + index}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    file={file}
                  />
                ))}
              </div>
            </div>
            <div className="col-4 py-3 border-start border-1 border-white">
              <Overview
                closeModal={closeModal}
                file={selectedFile}
                fieldName={fieldName}
              />
            </div>
            <div className="col-12 pt-3 border-top border-white border-1">
              <UploadFileButton />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default UploadFilesManager

const FileItem = ({ file, selectedFile, setSelectedFile }) => {
  const [isSelected, setIsSelected] = useState(false)

  useEffect(() => {
    if (selectedFile === file) {
      setIsSelected(true)
    } else {
      setIsSelected(false)
    }
  }, [selectedFile, file])

  const { url, alternativeText } = file
  return (
    <div
      className={"col-3 pointer "}
      onClick={() => {
        setSelectedFile(file)
      }}
    >
      <img
        src={url}
        alt={alternativeText}
        width={100}
        height={100}
        className={`${isSelected ? "border border-white border-3" : ""}`}
      />
    </div>
  )
}

const Overview = ({ closeModal, file, fieldName }) => {
  const formik = useFormikContext()

  // Const {name, url, alternativeText} = file
  return (
    <>
      {file && (
        <div className="row text-center">
          <div className="col-12">
            <Typography variant="bodySmBold" semanticTag="h2">
              {file.name}
            </Typography>
          </div>
          <div className="col-12">
            <img
              src={file.url}
              alt={file.alternativeText}
              width={100}
              height={100}
              className={""}
            />
          </div>
          <div className="col-12 mt-5 ">
            <Button
              label={"Select file"}
              onClick={() => {
                formik.setFieldValue(fieldName, file.url)
                closeModal()
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}

function useHook(arg0, arg1) {
  throw new Error("Function not implemented.")
}
