import React, { useEffect, useState } from "react";
import UserDataContext from "../context/UserDataContext";
import userAuth from "../utility/auth/authProvider";
import { getFiles, getSessionContent } from "../utility/fetch/fetchSessions";

const UserDataContextProvider = ({ children }) => {
  const [files, setFiles] = useState([]);
  const [filesLoading, setFilesLoading] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userData, setUserData] = useState(null);
  const [userAuthData, setUserAuthData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [authError, setAuthError] = useState(null);
  const [showNextScreen, setShowNextScreen] = useState(false);

  // // console.log("IsAuth", userAuth.checkAuth(), Cookies.getCookie("userToken"))

  const logIn = async (identifier, password) => {
    await userAuth.login(identifier, password).then((res) => {
      if (res.status === 400) {
        setAuthError({ ...res.data, isAuthError: true });
      } else {
        setIsAuth(userAuth.checkAuth);
        setUserAuthData(res);
        setAuthError(null);
      }
    });
  };

  const logOut = () => {
    userAuth.logout();
    setIsAuth(userAuth.checkAuth);
  };

  const fetchContent = async () => {
    const userAuthData = userAuth.getUserProfile();
    const userToken = userAuth.getUserToken();
    // // console.log("userAuthData", userAuthData)
    await getSessionContent(userAuthData.email, userToken).then((res) => {
      setIsLoading(res.isLoading);
      setUserData(res.data);
      // console.log(res?.data[0]?.role?.type=="admin");
      setIsAdmin(res?.data[0]?.role?.type == "admin");
    });
  };

  const fetchFiles = async () => {
    const userAuthData = userAuth.getUserProfile();
    const userToken = userAuth.getUserToken();
    // // console.log("userAuthData", userAuthData)
    await getFiles(userToken)
      .then((res) => {
        setFilesLoading(res.isLoading);
        setFiles(res.data);
      })
      .catch((err) => {
        setFilesLoading(false);
        setFiles(null);
      });
  };

  useEffect(() => {
    setIsAuth(userAuth.checkAuth);
  }, []);

  useEffect(() => {
    fetchContent();
    fetchFiles();
  }, [isAuth]);

  return (
    <UserDataContext.Provider
      value={{
        showNextScreen,
        setShowNextScreen,
        files,
        fetchFiles,
        filesLoading,
        authError,
        logIn,
        logOut,
        setIsAuth,
        isAuth,
        userData,
        isLoading,
        isAdmin,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export default UserDataContextProvider;
