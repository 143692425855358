import React, { useContext } from "react"
import Button from "../reusable/Button"
import Typography from "../reusable/typography/Typography"
import userAuth from "../../utility/auth/authProvider"
import slugify from "slugify"
// import { FiEdit } from 'react-icons/fi';
import { deleteSessionContent, getHistoryContentById, getSessionContentById, postSessionContent } from "../../utility/fetch/fetchSessions"
import { useNavigate } from "react-router-dom"
import { SessionOptionDropdown } from "../mui/FieldEditDropdown"
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import UserDataContext from "../../context/UserDataContext"
import { useMediaQuery } from '@mui/material';
import lastUpdateHelper from "../../utility/lastUpdateHelper"
const SessionTrainingCollectionItem = ({session,fetchData}) => {
	const {isAdmin} = useContext(UserDataContext)
	const {title, duration,last_used} = session.attributes
	const userToken = userAuth.getUserToken()
	const userId = userAuth.getUserProfile().id
	// // console.log("Session Data: ", session)

async function handleDuplicate() {
	const result=await getSessionContentById(session.id)
	const data=result.data.data.attributes
	// console.log(data,"data");
const modifiedData = {
	title: data.title+" copy",
	quote: data.quote,
	switch: data.switch,
	duration:data.duration,
	users_permissions_users: userId,
	prepare_to_start: data.prepare_to_start,
	prepare_to_stretch: data.prepare_to_stretch,
	thankyou: data.thankyou,
	stretch: data.stretch?.map(item => {
		const { id, ...data } = item;
		if(!data.seconds)
		data.seconds=30
		return {...data };
	  }),
	  warm_up: data.warm_up?.map(item => {
		const { id, ...data } = item;
		if(!data.seconds)
		data.seconds=30
		return {...data };
	  }),
	  fundamentals: data.fundamentals?.map(item => {
		const { id, ...data } = item;
		if(!data.seconds)
		data.seconds=30
		return {...data };
	  }),
	rounds: data.rounds?.map(round => {
	  const { id,round_number, sections } = round;
	  const modifiedSections = sections.map(section => {
		const { id, ...rest } = section;
		return {round_number, ...rest };
	  });
	  return { sections: modifiedSections };
	})
  };
  // console.log(modifiedData);
  
		const response = await postSessionContent(modifiedData, userToken)
		if (response.data?.response?.data?.error?.status) {
            alert("Failed to Duplicate")
            // console.log(response.data.response.data.error.message)
          } else {
			fetchData()
          }
	}
	

	const navigate=useNavigate()

	function handleHistoryNavigate(e){
		navigate(`sessions/detail/${session.id}`)
	}
	function handleEdit(e){
		navigate(`update/${session.id}`)
	}
	async function handleDelete(e){
	const result=await	deleteSessionContent(session.id,userToken)
	fetchData()
	}
	function handleStart(e){
		e.stopPropagation()
		navigate(`sessions/${session.id}`)
	}

	const isTablet = useMediaQuery('(max-width:860px)');
	console.log(isTablet,"klkl");
	return (
		<div style={{height:"150px",backgroundColor:"rgb(23, 23, 23)"}} className={"mt-3 mt-sm-2 d-flex  justify-content-between  border border-dark rounded rounded-5  w-100 px-3" }>
		<div className=" w-100 position-relative d-flex justify-content-between align-items-top " >
					<div className=" p-2">
						<Typography className={"col-auto word-wrap"} sx={{fontSize: isTablet ? '11px' : '45px' }} variant="bodyLgBold" >{title}</Typography>
						<Typography className={" col-auto word-wrap"} variant="bodySm" semanticTag="p">{(duration||"45")+"min"}</Typography>
						{last_used&&<>
						
						<Typography className={" col-auto word-wrap"} variant="bodySm" semanticTag="span">Played</Typography>
						<Typography className={" col-auto word-wrap"} variant="bodySm" semanticTag="p">{lastUpdateHelper(last_used)}</Typography>
						</>}
					</div>
					<div className="">
					<SessionOptionDropdown
						
						onEdit={handleEdit}
						onDuplicate={handleDuplicate}
						onDelete={handleDelete}
						/>
					</div>
					<div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', right: '10%', top: '50%', transform: 'translate(-50%, -50%)' }}>
					<PlayCircleFilledWhiteIcon fontSize="large" style={{fontSize:50}} onClick={handleStart}/>
					</div>
			</div>
		</div>
	)
}


export default SessionTrainingCollectionItem
