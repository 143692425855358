import React from "react"
import { Formik } from "formik"
import * as yup from "yup"
import Button from "../../reusable/Button"
import { postFile } from "../../../utility/fetch/fetchSessions"
import userAuth from "../../../utility/auth/authProvider"
import UserDataContext from "../../../context/UserDataContext"

const UploadFileButton = () => {
  const userToken = userAuth.getUserToken()
  const { fetchFiles } = React.useContext(UserDataContext)
  const initialValues = {
    file: null
  }

  return (
    <div className="container">
      <Formik
        initialValues={initialValues}
        onSubmit={async values => {
          const formData = new FormData()

          formData.append("files", values.file, values.file?.name)
          const response = await postFile(formData, userToken)

          if (!response.isLoading) {
            fetchFiles()
          }
        }}
        validationSchema={yup.object().shape({
          file: yup.mixed().required()
        })}
        render={({ values, handleSubmit, setFieldValue }) => (
          <div className={"d-flex"}>
            <div className="">
              <label htmlFor="file"></label>
              <input
                id="file"
                name="file"
                type="file"
                onChange={event => {
                  setFieldValue("file", event.currentTarget.files[0])
                }}
                className="input-transparent"
              />
            </div>
            <Button
              disabled={values.file === null}
              onClick={handleSubmit}
              label={"Upload file"}
            />
          </div>
        )}
      />
    </div>
  )
}

export default UploadFileButton
function useContext(UserDataContext) {
  throw new Error("Function not implemented.")
}
