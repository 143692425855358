import React, { useContext } from "react";
import UserDataContext from "../../context/UserDataContext";
import Typography from "./typography/Typography";
import { useMediaQuery } from "@mui/material";

const BoxSacks = ({ boxing, col2, label = "bag", nextScreen, next, data }) => {
    const isMobileView = useMediaQuery("(max-width: 600px)");
    const { showNextScreen } = useContext(UserDataContext);
    const movements = getMovementsInArr(boxing);

    const labelClassName = `text-start text-white  ${
        col2 ? "" : "pt-0 pb-0  "
    }`;
    const containerBagsClassName = `d-flex flex-wrap justify-content-start g-0  ${
        col2
            ? "mt-3"
            : movements && nextScreen && showNextScreen
            ? " mt-4 mb-2 justify-content-center items-center gap-0"
            : " mt-4 mb-2 justify-content-center items-center gap-3"
    }`;

    return (
        <>
            {movements ? (
                <>
                    <div className="">
                        {/* fontsize for nex bag */}
                        <div
                            className={`${
                                col2 ? "" : "pe-5 ms-2 mt-2"
                            }   next-text-value  `}
                        >
                            <Typography
                                style={{
                                    fontSize: col2 ? "2rem" : "3rem",
                                    fontWeight: "200",
                                    fontFamily: "'Roboto', sans-serif",
                                    textAlign: "left",
                                }}
                                semanticTag="h1"
                                variant="heading1"
                                className={labelClassName}
                            >
                                {label?.toUpperCase()}
                            </Typography>
                        </div>
                    </div>
                    <div className="col-12 next-text-label ">
                        <div className={containerBagsClassName}>
                            {movements.map((movement, i) => {
                                let colClass = "";
                                if (movements.length > 6 && !next)
                                    colClass = "col-3";
                                else if (movements.length === 4 && !next)
                                    colClass = "col-3";
                                else if (
                                    (movements.length > 4) &
                                        (movements.length <= 6) &&
                                    !next
                                )
                                    colClass = "col-2";
                                else if (movements.length == 2)
                                    colClass = "col-4";
                                else if (movements.length == 1)
                                    colClass = "col";
                                else if (next) colClass = "col";
                                else colClass = "col";
                                return (
                                    <div className={" mb-2"}>
                                        {movement === "1" ||
                                        movement === "2" ||
                                        movement === "3" ||
                                        movement === "4" ||
                                        movement === "5" ||
                                        movement === "6" ? (
                                            <BoxSacksComponent
                                                key={movement + "-" + i}
                                                number={movement}
                                                col2={col2}
                                                className={
                                                    col2 ? `col-4` : `col-9`
                                                }
                                            />
                                        ) : movement ? (
                                            <div
                                                style={{}}
                                                key={movement + "-" + i}
                                                className={`d-flex h-100 justify-content-start align-items-center  `}
                                            >
                                                <Typography
                                                    semanticTag="h1"
                                                    style={{
                                                        paddingTop: col2
                                                            ? ""
                                                            : "55px",
                                                        fontSize: col2
                                                            ? "2rem"
                                                            : movement.length >
                                                              3
                                                            ? "7rem"
                                                            : "10rem",
                                                        fontWeight: col2
                                                            ? "700"
                                                            : "200",
                                                        fontFamily: col2
                                                            ? "Roboto"
                                                            : "",
                                                        lineHeight: "1",
                                                        margin: "0",
                                                        textAlign: col2
                                                            ? "left"
                                                            : "center",
                                                        overflowWrap:
                                                            "break-word",
                                                        margin: "0 9px",

                                                        // whiteSpace: isMobileView
                                                        //     ? "normal"
                                                        //     : "nowrap",
                                                    }}
                                                    variant="heading2"
                                                    className={
                                                        "text-white px-20"
                                                    }
                                                >
                                                    {movement?.toUpperCase()}
                                                </Typography>
                                            </div>
                                        ) : null}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default BoxSacks;

const BoxSacksComponent = ({ number, className, col2 }) => (
    <div
        className="img-container"
        style={{ maxWidth: "100%", textAlign: "center" }}
    >
        {col2 ? (
            <h1
                style={{
                    fontFamily: "Roboto",
                    color: "#FE1E3C",
                    fontWeight: "bold",
                    fontSize: "40px",
                }}
            >
                {" "}
                {number}
            </h1>
        ) : (
            <img
                src={`/boxsacks/boxsack_${number}.png`}
                alt={`boxsack_${number}`}
                className={`${className} floor_bag_image img-fluid`}
                style={{
                    height: "200px",
                    width: "auto",
                    objectFit: "fill",
                    margin: "0px 12px",
                    // padding: "0px",
                }}
            />
        )}
    </div>
);

// Fix case of words with - in them
const getMovementsInArr = (boxing) => {
    const newArr = boxing?.trim().split("-");
    return newArr;
};
