import React, { useContext } from "react";
import UserDataContext from "../context/UserDataContext";
import SessionCreationForm from "../components/forms/SessionCreationForm";
import NavBar from "../components/reusable/NavBar";
import Typography from "../components/reusable/typography/Typography";

const SessionCreationScreen = () => {
  const { isLoading, userData } = useContext(UserDataContext);
  const content = userData;

  return <SessionCreationForm />;
};

export default SessionCreationScreen;
