import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import UserDataContextProvider from "./provider/UserDataContextProvider";

// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <UserDataContextProvider>
        <div
            className={"position-relative"}
            style={{
                backgroundColor: "#02111E",
                color: "white",
            }}
        >
            <div className="container text-bold">
                <App />
            </div>
        </div>
    </UserDataContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
