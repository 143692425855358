/* eslint-disable camelcase */
import { Form, Formik, useFormikContext } from "formik";
import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import userAuth from "../utility/auth/authProvider";
import {
  getSessionContentById,
  getSessionSchema,
  postSessionContent,
  updateSessionSchema,
} from "../utility/fetch/fetchSessions";
import Button from "../components/reusable/Button";
import FieldFormInput from "../components/forms/fields/FieldFormIntput";
import SectionBoxForm from "../components/forms/sections/SectionBoxForm";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";
import SectionDynamicForm from "../components/forms/sections/SectionDynamicForm";
import UserDataContext from "../context/UserDataContext";
import AdminButton from "../components/mui/AdminButton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { removeAndShift, addAndShift } from "../utility/subSectionHelpers";

const SessionCreationForm = () => {
  const userToken = userAuth.getUserToken();
  const [rounds, setRounds] = useState([1, 2, 3, 4]);
  const { files, filesLoading } = useContext(UserDataContext);
  const [data, setData] = useState();
  const [oldData, setOldData] = useState();
  // Const [counter, setCounter] = useState(1)
  const [activeTab, setActiveTab] = useState("OG");
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const result = await getSessionSchema();
    // console.log(result.data.data.attributes);
    setData(result.data.data.attributes || null);
    setOldData(result.data.data.attributes?.oldSchema || null);
  }

  if (!data) return;
  return (
    <div>
      <Tabs value={activeTab} onChange={handleTabChange} centered>
        <Tab label="OG" value="OG" />
        <Tab label="Hero" value="Hero" />
        <Tab label="Fundamentals" value="fundamentals" />
      </Tabs>

      {activeTab === "OG" && <OGTab data={data.OG} oldData={oldData} />}
      {activeTab === "Hero" && <HeroTab data={data.Hero} oldData={oldData} />}
      {activeTab === "fundamentals" && (
        <FundamentalsTab data={data.Fundamentals} oldData={oldData} />
      )}
    </div>
  );
};

export default SessionCreationForm;

const SessionSchemaForm = ({ data, setData, oldData, name }) => {
  const navigate = useNavigate();
  const userToken = userAuth.getUserToken();
  const [isLoading, setIsLoading] = useState(false);
  function handleReset(e, setValues) {
    e.preventDefault();
    setData(oldData);
    setValues(oldData);
  }
  function handleDataChange(value, key, props) {
    const { subSecIndex, round, index, formik, remove } = props;
    const { values, setValues } = formik;
    if (remove) {
      switch (key) {
        case "round": {
          const updatedRounds = [...data.rounds];
          updatedRounds.splice(index, 1);
          setData({ ...data, rounds: updatedRounds });

          const newFormValues = { ...formik.values, rounds: updatedRounds };
          setValues(newFormValues);

          break;
        }
        case "section": {
          const updatedSections = [...data.rounds[round].sections];
          updatedSections.splice(index, 1);
          setData((prevData) => ({
            ...prevData,
            rounds: [
              ...prevData.rounds.slice(0, round),
              {
                ...prevData.rounds[round],
                sections: updatedSections,
              },
              ...prevData.rounds.slice(round + 1),
            ],
          }));

          const updatedFormValues = { ...formik.values };
          updatedFormValues.rounds[round].sections.splice(index, 1);
          setValues(updatedFormValues);
          break;
        }
        case "subsection": {
          const updatedSections = { ...data.rounds[round].sections[index] };
          removeAndShift(updatedSections, subSecIndex);
          data.rounds[round].sections[index] = updatedSections;
          setData(data);
          const newFormValues = { ...formik.values };
          removeAndShift(newFormValues, subSecIndex);
          setValues(newFormValues);

          break;
        }
        case "active_recovery": {
          const updatedSections = [...data.rounds[round].sections];
          delete updatedSections[index].active_recovery;
          setData((prevData) => ({
            ...prevData,
            rounds: [
              ...prevData.rounds.slice(0, round),
              {
                ...prevData.rounds[round],
                sections: updatedSections,
              },
              ...prevData.rounds.slice(round + 1),
            ],
          }));

          const updatedFormValues = { ...formik.values };
          delete updatedFormValues.rounds[round].sections[index]
            .active_recovery;
          setValues(updatedFormValues);

          break;
        }
        default: {
          const updatedData = [...values[key]];
          updatedData.splice(index, 1);
          setData({ ...data, [key]: updatedData });

          const newFormValues = { ...formik.values, [key]: updatedData };
          setValues(newFormValues);

          break;
        }
      }
    } else {
      switch (key) {
        case "round": {
          const updatedRounds = [...values.rounds];
          updatedRounds.splice(index + 1, 0, value);
          setData({ ...data, rounds: updatedRounds });

          const newFormValues = { ...formik.values, rounds: updatedRounds };
          setValues(newFormValues);

          break;
        }
        case "section": {
          const updatedRounds = values.rounds.map((r, i) => {
            if (i === round) {
              const updatedSections = [...r.sections];
              updatedSections.splice(index + 1, 0, value);
              return { ...r, sections: updatedSections };
            }
            return r;
          });
          setData({ ...data, rounds: updatedRounds });

          // Reset Formik form values and field structure
          const newFormValues = { ...formik.values, rounds: updatedRounds };
          setValues(newFormValues);

          break;
        }
        case "subsection": {
          const updatedSections = { ...data.rounds[round].sections[index] };
          addAndShift(updatedSections, subSecIndex);
          console.log(updatedSections, "dataval", "add");
          data.rounds[round].sections[index] = updatedSections;
          setData(data);
          const newFormValues = { ...formik.values };
          addAndShift(newFormValues, subSecIndex);
          setValues(newFormValues);
          break;
        }
        case "active_recovery": {
          const updatedSections = [...data.rounds[round].sections];
          updatedSections[index].active_recovery = "Activity Recovery";
          setData((prevData) => ({
            ...prevData,
            rounds: [
              ...prevData.rounds.slice(0, round),
              {
                ...prevData.rounds[round],
                sections: updatedSections,
              },
              ...prevData.rounds.slice(round + 1),
            ],
          }));

          const updatedFormValues = { ...formik.values };
          updatedFormValues.rounds[round].sections[index].active_recovery =
            "Activity Recovery";
          setValues(updatedFormValues);

          break;
        }

        default:
          {
            const updatedData = [...values[key]];
            updatedData.splice(index + 1, 0, value);
            setData({ ...data, [key]: updatedData });

            const newFormValues = { ...formik.values, [key]: updatedData };
            setValues(newFormValues);
          }
          break;
      }
    }
  }
  return (
    <Formik
      initialValues={data}
      // validationSchema={SessionCreationSquema}
      // validateOnMount={true}
      onSubmit={async (values) => {
        // SetSubmitting(false)
        values.slug = slugify(values.title, { lower: true });
        values.rounds = values?.rounds?.map((item, index) => {
          return { ...item, round_number: index + 1 };
        });
        setIsLoading(true);
        const response = await updateSessionSchema(
          { [name]: values },
          userToken
        );
        setIsLoading(false);

        if (response.data?.response?.data?.error?.status) {
          alert("Failed to Update");
          // console.log(response.data.response.data.error.message)
        } else {
          navigate("/");
        }
      }}
    >
      {({ handleSubmit, handleChange, setValues, values, errors, touched }) => (
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Button
            label={"Load Default"}
            className={"my-3"}
            onClick={(e) => handleReset(e, setValues)}
          />
          <div className="">
            <div className="row ">
              <div className=" col-12 col-sm-6">
                <FieldFormInput
                  label={"Title"}
                  name={nameFields.title}
                  type="text"
                  errors={errors}
                  touched={touched}
                  maxLength={20}
                />
              </div>
              <div className="col-12 col-sm-6">
                <FieldFormInput
                  textarea
                  label={"Quote"}
                  name={nameFields.quote}
                  type="text"
                  errors={errors}
                  touched={touched}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6">
                <SectionDynamicForm
                  className=""
                  handleDataChange={handleDataChange}
                  name={nameFields.warmup}
                  col12
                />
              </div>
              <div className="col-12 col-sm-6">
                <SectionDynamicForm
                  handleDataChange={handleDataChange}
                  name={nameFields.fundamentals}
                  col12
                />
              </div>
            </div>

            {/* </div> */}

            <div className=" col-12 mt-4 text-center">
              {data.rounds.length > 0 ? (
                <div className=" row mt-3 justify-content-center">
                  {data?.rounds.map((item, roundNumber) => (
                    <SectionBoxForm
                      key={roundNumber}
                      roundNumber={roundNumber}
                      round={item}
                      handleDataChange={handleDataChange}
                      data={data}
                      setData={setData}
                    />
                  ))}
                </div>
              ) : null}
            </div>

            <div className="row">
              <div className=" col-sm-6 col-12">
                <SectionDynamicForm name={nameFields.switch} col12 />
                <SectionDynamicForm name={nameFields.prepareToStart} col12 />
                <SectionDynamicForm name={nameFields.prepareToStretch} col12 />
                <SectionDynamicForm name={nameFields.thankyou} col12 />
              </div>

              <div className="col-sm-6 col-12">
                <SectionDynamicForm
                  handleDataChange={handleDataChange}
                  name={nameFields.stretch}
                  col12
                />
              </div>
            </div>

            <div className="col-12 text-center mt-5">
              <Button
                type={"submit"}
                loading={isLoading}
                label={"Update Session"}
                disabled={Object.keys(errors).length > 0}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export const nameFields = {
  warmup: "warm_up",
  title: "title",
  stretch: "stretch",
  quote: "quote",
  fundamentals: "fundamentals",
  switch: "switch",
  prepareToStart: "prepare_to_start",
  prepareToStretch: "prepare_to_stretch",
  thankyou: "thankyou",
};

function OGTab({ data: initial, oldData }) {
  const [data, setData] = useState(initial);
  return (
    <SessionSchemaForm
      data={data}
      oldData={oldData}
      setData={setData}
      name="OG"
    />
  );
}

// Create similar components for Hero and Fundamentals tabs
function HeroTab({ data: initial, oldData }) {
  const [data, setData] = useState(initial);
  return (
    <SessionSchemaForm
      data={data}
      oldData={oldData}
      setData={setData}
      name="Hero"
    />
  );
}

function FundamentalsTab({ data: initial, oldData }) {
  const [data, setData] = useState(initial);
  return (
    <SessionSchemaForm
      data={data}
      oldData={oldData}
      setData={setData}
      name="Fundamentals"
    />
  );
}
