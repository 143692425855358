import axios from "axios"
import Cookies from "./Cookie"

// Request API.
const userAuth = {
  async login(identifier, password) {
    const response = axios
      .post(`${process.env.REACT_APP_STRAPI_URL_BASE}/api/auth/local`, {
        identifier,
        password
      })
      .then(response => {
        // Handle success.
        // // console.log("Well done!")
        // // console.log("User profile", response.data.user)
        // // console.log("User token", response.data.jwt)
        Cookies.setCookie("userToken", response.data.jwt, 1)
        Cookies.setCookie("userProfile", JSON.stringify(response.data.user), 1)
        return response.data
      })
      .catch(
        error =>
          // Handle error.
          // // console.log("An error occurred:", error.response)
          error.response
      )
    return response
  },
  logout() {
    Cookies.deleteCookie("userToken")
    Cookies.deleteCookie("userProfile")
  },
  getUserToken() {
    return Cookies.getCookie("userToken")
  },
  getUserProfile() {
    return JSON.parse(Cookies.getCookie("userProfile"))
  },
  checkAuth() {
    return Cookies.getCookie("userToken") !== "{}"
  }
}

export default userAuth
