import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UserDataContext from "../context/UserDataContext";
import {
  getSessionContentById,
  getSessionContentBySlug,
} from "../utility/fetch/fetchSessions";
import SessionCreationForm from "../components/forms/SessionCreationForm";
import SessionEditionForm from "../components/forms/SessionEditionForm";
import NavBar from "../components/reusable/NavBar";
import Typography from "../components/reusable/typography/Typography";

const EditSessionComponent = () => {
  const { id: dataId } = useParams();
  const [content, setContent] = useState();
  const [dataLoading, setDataLoading] = useState(true);

  const fetchContent = async () => {
    await getSessionContentById(dataId).then((res) => {
      setDataLoading(res.isLoading);
      setContent(res.data);
    });
  };

  useEffect(() => {
    fetchContent();
  }, []);

  const session = dataLoading || content.data.attributes;
  const data = dataLoading || content.data;

  const { id, attributes } = data;
  if (dataLoading) return;
  return <SessionEditionForm id={id} attributes={attributes} />;
};

export default EditSessionComponent;
