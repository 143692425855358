import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import AdminButton from "./AdminButton";
import { LoadingOverlaySmall } from "./LoadingOverlay";
import { Button, TableSortLabel } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";

export default function TableMui({
  th,
  td,
  styleTableTh,
  styleTableContainer,
  styleTableThead,
  styleTableTd,
  link,
  showId = false,
  btnName,
  btnSize,
  btnStyle,
  customFields,
  customBtn,
  loading,
  onSort,
  
}) {
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnName) => {
    const direction =
      columnName === sortColumn && sortDirection === "asc" ? "desc" : "asc";
    setSortColumn(columnName);
    setSortDirection(direction);
    onSort && onSort(columnName, direction);
  };


  const found = (key) => {
    let data = customFields.find((obj) => {
      return obj.name === key;
    });
    return data;
  };
  return (
    <>
    <TableContainer style={styleTableContainer}>
      <Table aria-label="simple table">
      <TableHead sx={styleTableThead}>
            <TableRow>
              {/* get [array of th object(values)] and map thier values */}
              {Object.keys(th).map((key, index) => {
                return (
                  <TableCell
                  key={index}
                  align="left"
                  sx={styleTableTh}
                
                >
                   {th[key]}
                 {onSort&& <TableSortLabel
                    onClick={() => handleSort(key)}
                    active={sortColumn === key}
                    direction={sortDirection}
                  > </TableSortLabel>}
                </TableCell>
                );
              })}
              {customBtn && (
                <TableCell align="center" sx={styleTableTh}>
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>


  {!loading&&<TableBody>
          {td?.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {Object.keys(th).map((key, ind) => {
                return (
                  <TableCell  key={ind} style={{whiteSpace: "nowrap" }} className="" align="left">
                    {key === "id"&&!showId?(
                      <Link className="" style={{textDecoration:'none'}} to={link + row[key]}> 
                        <AdminButton
                          name={btnName}
                          size={btnSize}
                          style={btnStyle}
                        />
                      </Link>
                    ) : (
                      customFields&&found(key)?found(key).data(row[key],row):
                      row[key]
                    )}
                    {key === "sr" && index + 1}
                  </TableCell>
                );
              })}
                 {customBtn&&<TableCell  align="center">
                   {customBtn(row,index)}
                  </TableCell>}
            </TableRow>
          ))}
        </TableBody>}







      </Table>
    </TableContainer>
        { loading&&<LoadingOverlaySmall open={loading}/>}
        </>
  );
}
