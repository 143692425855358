import { Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import * as yup from "yup";
import UserDataContext from "../../context/UserDataContext";
import Button from "../reusable/Button";
import Typography from "../reusable/typography/Typography";
import FieldFormInput from "./fields/FieldFormIntput";

const LogInFormSquema = yup.object().shape({
  username: yup.string().required("Username is required"),
  password: yup.string().required("Password is required"),
});

const LogInForm = () => {
  const { logIn, authError } = useContext(UserDataContext);
  const initialValues = {
    username: "",
    password: "",
  };
  const [isLoading, setIsLoading] = useState(false);

  // // console.log("authError", authError)

  return (
    <div className="  mt-5">
      {authError && (
        <div className="col-12 mb-3">
          <Typography semanticTag="span" variant="bodySm">
            {authError.error.message}, please try again
          </Typography>
        </div>
      )}
      <div className="">
        <div className="  ">
          <Formik
            initialValues={initialValues}
            validationSchema={LogInFormSquema}
            onSubmit={async (values, { setSubmitting }) => {
              // // console.log(values)
              setIsLoading(true);
              const login = await logIn(values.username, values.password);
              console.log(login);
              setIsLoading(false);
            }}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className=" d-flex justify-content-center">
                  <div className="col-sm-10 col-12">
                    <div className="row">
                      <div className="col-sm-10 col-12">
                        <FieldFormInput name="username" label="Username" />
                      </div>
                      <div className="col-sm-10 col-12">
                        <FieldFormInput
                          name="password"
                          label="Password"
                          type={"password"}
                        />
                      </div>
                      <div className="col-sm-10 col-12 justify-content-center">
                        <Button
                          type="submit"
                          loading={isLoading}
                          label={"Log In"}
                          className={"mt-4 w-100"}
                          bgColor={"red"}
                          textColor={"light"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default LogInForm;
