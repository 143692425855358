/* eslint-disable camelcase */
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import userAuth from "../../utility/auth/authProvider";
import {
  getSessionSchema,
  postSessionContent,
} from "../../utility/fetch/fetchSessions";
import Button from "../reusable/Button";
import FieldFormInput from "./fields/FieldFormIntput";
import SectionBoxForm from "./sections/SectionBoxForm";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";
import SectionDynamicForm from "./sections/SectionDynamicForm";
import UserDataContext from "../../context/UserDataContext";
import { calculateTime } from "../calculateTime";
import ButtonGroup from "@mui/material/ButtonGroup";
import MUIButton from "@mui/material/Button";
import { removeAndShift, addAndShift } from "../../utility/subSectionHelpers";
import { CircularProgress } from "@mui/material";
import schemaData from "./schema.json";

const SessionCreationSquema = yup.object().shape({
  title: yup.string().required("required"),
  quote: yup.string().required("required"),
  warm_up: yup
    .array()
    .of(
      yup.object().shape({
        move: yup.string().required("required"),
        seconds: yup.number().required("required"),
      })
    )
    .min(1, "required"),
  stretch: yup
    .array()
    .of(
      yup.object().shape({
        move: yup.string().required("required"),
        seconds: yup.number().required("required"),
      })
    )
    .min(1, "required"),
  fundamentals: yup.array().of(
    yup.object().shape({
      move: yup.string().required("required"),
      seconds: yup.number().required("required"),
    })
  ),
  users_permissions_users: yup.number(),
  rounds: yup
    .array()
    .of(
      yup.object().shape({
        // Required("Round number is required"),
        sections: yup
          .array()
          .of(
            yup.object().shape({
              bag_1: yup.string().required("required"), // Required("Section number is required"),
              floor_1: yup.string().required("required"), // Required("Section number is required"),
              floor_bag_time_1: yup.number(), // Required("Section number is required"),
            })
          )
          .min(1, "At least one section is required"),
      })
    )
    .min(1, "At least 1 round is required"),
  // .required("Rounds are required"),
});

function SchemaTypeButtons({ schemaType, setSchemaType }) {
  const getColor = (active) => {
    if (active)
      return {
        backgroundColor: "rgb(108,117,125)",
        color: "white",
        borderColor: "black",
      };
    else return { color: "white", borderColor: "black" };
  };
  return (
    <ButtonGroup variant="outlined" aria-label="outlined primary button group">
      <MUIButton
        onClick={() => setSchemaType("OG")}
        variant={schemaType === "OG" ? "contained" : "outlined"}
        style={getColor(schemaType === "OG")}
      >
        OG
      </MUIButton>
      <MUIButton
        onClick={() => setSchemaType("Hero")}
        variant={schemaType === "Hero" ? "contained" : "outlined"}
        style={getColor(schemaType === "Hero")}
      >
        Hero
      </MUIButton>
      <MUIButton
        onClick={() => setSchemaType("Fundamentals")}
        variant={schemaType === "Fundamentals" ? "contained" : "outlined"}
        style={getColor(schemaType === "Fundamentals")}
      >
        Fundamentals
      </MUIButton>
    </ButtonGroup>
  );
}

const SessionCreationForm = () => {
  const userId = userAuth.getUserProfile().id;
  const userToken = userAuth.getUserToken();
  const [rounds, setRounds] = useState([1, 2, 3, 4]);
  const { files, filesLoading } = useContext(UserDataContext);
  const [data, setData] = useState(
    JSON.parse(localStorage.getItem("mysessionData"))
  );
  const [isLoading, setIsloading] = useState({
    data: false,
    button: false,
  });

  const [schemaType, setSchemaType] = useState("OG");

  const navigate = useNavigate();

  const [result, setResult] = useState();
  useEffect(() => {
    // if (result) setData(result[schemaType] || null);
    setData(schemaData?.data?.attributes[schemaType]);
    //console.log(result[schemaType]);
  }, [schemaType, result]);

  // console.log(data?.rounds[0]?.sections?.length, "length");

  console.log(data?.rounds);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const result = await getSessionSchema();

    // console.log(result.data.data.attributes);
    setResult(result.data.data.attributes || null);
  }

  function handleDataChange(value, key, props) {
    console.log(props);
    const { round, subSecIndex, index, formik, remove } = props;
    const { values, setValues } = formik;

    if (remove) {
      switch (key) {
        case "round": {
          const updatedRounds = [...data.rounds];
          updatedRounds.splice(index, 1);
          setData({ ...data, rounds: updatedRounds });

          const newFormValues = { ...formik.values, rounds: updatedRounds };
          setValues(newFormValues);

          break;
        }
        case "section": {
          const updatedSections = [...data.rounds[round].sections];
          updatedSections.splice(index, 1);
          setData((prevData) => ({
            ...prevData,
            rounds: [
              ...prevData.rounds.slice(0, round),
              {
                ...prevData.rounds[round],
                sections: updatedSections,
              },
              ...prevData.rounds.slice(round + 1),
            ],
          }));

          const updatedFormValues = { ...formik.values };
          updatedFormValues.rounds[round].sections.splice(index, 1);
          setValues(updatedFormValues);
          break;
        }

        case "active_recovery": {
          const updatedSections = [...data.rounds[round].sections];
          delete updatedSections[index].active_recovery;
          setData((prevData) => ({
            ...prevData,
            rounds: [
              ...prevData.rounds.slice(0, round),
              {
                ...prevData.rounds[round],
                sections: updatedSections,
              },
              ...prevData.rounds.slice(round + 1),
            ],
          }));

          const updatedFormValues = { ...formik.values };
          delete updatedFormValues.rounds[round].sections[index]
            .active_recovery;
          setValues(updatedFormValues);

          break;
        }
        case "subsection": {
          const updatedSections = { ...data.rounds[round].sections[index] };
          removeAndShift(updatedSections, subSecIndex);
          data.rounds[round].sections[index] = updatedSections;
          setData(data);
          const newFormValues = { ...formik.values };
          removeAndShift(newFormValues, subSecIndex);
          setValues(newFormValues);

          break;
        }
        default: {
          const updatedData = [...values[key]];
          updatedData.splice(index, 1);
          setData({ ...data, [key]: updatedData });

          const newFormValues = { ...formik.values, [key]: updatedData };
          setValues(newFormValues);

          break;
        }
      }
    } else {
      switch (key) {
        case "round": {
          const updatedRounds = [...values.rounds];
          updatedRounds.splice(index + 1, 0, value);
          setData({ ...data, rounds: updatedRounds });

          const newFormValues = { ...formik.values, rounds: updatedRounds };
          setValues(newFormValues);

          break;
        }
        case "section": {
          const updatedRounds = values.rounds.map((r, i) => {
            if (i === round) {
              const updatedSections = [...r.sections];
              updatedSections.splice(index + 1, 0, value);
              return { ...r, sections: updatedSections };
            }
            return r;
          });
          setData({ ...data, rounds: updatedRounds });

          // Reset Formik form values and field structure
          const newFormValues = { ...formik.values, rounds: updatedRounds };
          setValues(newFormValues);

          break;
        }
        case "subsection": {
          const updatedSections = { ...data.rounds[round].sections[index] };
          addAndShift(updatedSections, subSecIndex);

          data.rounds[round].sections[index] = updatedSections;
          setData(data);
          const newFormValues = { ...formik.values };
          addAndShift(newFormValues, subSecIndex);
          setValues(newFormValues);
          break;
        }

        case "active_recovery": {
          const updatedSections = [...data.rounds[round].sections];
          updatedSections[index].active_recovery = "Activity Recovery";
          setData((prevData) => ({
            ...prevData,
            rounds: [
              ...prevData.rounds.slice(0, round),
              {
                ...prevData.rounds[round],
                sections: updatedSections,
              },
              ...prevData.rounds.slice(round + 1),
            ],
          }));

          const updatedFormValues = { ...formik.values };
          updatedFormValues.rounds[round].sections[index].active_recovery =
            "Activity Recovery";
          setValues(updatedFormValues);

          break;
        }

        default:
          {
            const updatedData = [...values[key]];
            updatedData.splice(index + 1, 0, value);
            setData({ ...data, [key]: updatedData });

            const newFormValues = { ...formik.values, [key]: updatedData };
            setValues(newFormValues);
          }
          break;
      }
    }
  }

  function handleSchemaType(type) {
    if (type == schemaType) return;
    setData(null);
    setSchemaType(type);
  }

  if (!data) return;
  return (
    <div>
      {isLoading?.data ? (
        <div
          className="row g-12"
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "30px 0",
          }}
        >
          <CircularProgress size={80} />
        </div>
      ) : null}

      <SchemaTypeButtons
        schemaType={schemaType}
        setSchemaType={handleSchemaType}
      />
      <Formik
        initialValues={data}
        validationSchema={SessionCreationSquema}
        validateOnMount={true}
        onSubmit={async (values) => {
          values.duration = calculateTime(values);
          values.users_permissions_users = [userId];
          console.log(values);
          setIsloading({
            data: false,
            button: true,
          });

          const response = await postSessionContent(values, userToken);
          setIsloading({
            data: false,
            button: false,
          });

          if (response.data?.response?.data?.error?.status) {
            // // console.log(response.data.response.data.error.message)
          } else {
            localStorage.removeItem("mysessionData");
            navigate("/");
          }
        }}
      >
        {({ handleSubmit, handleChange, values, errors, touched }) => (
          <Form
            onSubmit={handleSubmit}
            autoComplete="off"
            onChange={() => {
              const jsonString = JSON.stringify(values);
              localStorage.setItem("mysessionData", jsonString);
            }}
          >
            <div className="">
              <div className="row ">
                <div className=" col-12 col-sm-6">
                  <FieldFormInput
                    label={"Title"}
                    name={nameFields.title}
                    type="text"
                    errors={errors}
                    touched={touched}
                    maxLength={20}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <FieldFormInput
                    textarea
                    label={"Quote"}
                    name={nameFields.quote}
                    type="text"
                    errors={errors}
                    touched={touched}
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-12 col-sm-6">
                  <SectionDynamicForm
                    className=""
                    handleDataChange={handleDataChange}
                    name={nameFields.warmup}
                    col12
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <SectionDynamicForm
                    handleDataChange={handleDataChange}
                    name={nameFields.fundamentals}
                    col12
                  />
                </div>
              </div>

              <div className=" col-12  mt-4 text-center">
                {data.rounds.length > 0 ? (
                  <div className=" row mt-3 justify-content-center">
                    {data?.rounds.map((item, roundNumber) => (
                      <SectionBoxForm
                        key={roundNumber}
                        roundNumber={roundNumber}
                        round={item}
                        schemaType={schemaType}
                        handleDataChange={handleDataChange}
                        data={data}
                        setData={setData}
                      />
                    ))}
                  </div>
                ) : null}
              </div>
              <div className="row">
                <div className=" col-sm-6 col-12">
                  <SectionDynamicForm name={nameFields.switch} col12 />
                  <SectionDynamicForm name={nameFields.prepareToStart} col12 />
                  <SectionDynamicForm
                    name={nameFields.prepareToStretch}
                    col12
                  />
                  <SectionDynamicForm name={nameFields.thankyou} col12 />
                </div>
                <div className="col-sm-6 col-12">
                  <SectionDynamicForm
                    handleDataChange={handleDataChange}
                    name={nameFields.stretch}
                    col12
                  />
                </div>
              </div>
              <div className="col-12 text-center mt-5">
                <p></p>
                <Button
                  type={"submit"}
                  loading={isLoading?.button}
                  label={"Create Session"}
                  // disabled={Object.keys(errors).length > 0}
                />
                <span style={{ paddingLeft: "10px" }}></span>
                <Button
                  label={"Cancel"}
                  type={"reset"}
                  onClick={() => {
                    localStorage?.removeItem("mysessionData");
                    navigate("/");
                  }}
                  // disabled={Object.keys(errors).length > 0}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SessionCreationForm;

export const nameFields = {
  warmup: "warm_up",
  title: "title",
  stretch: "stretch",
  quote: "quote",
  fundamentals: "fundamentals",
  switch: "switch",
  prepareToStart: "prepare_to_start",
  prepareToStretch: "prepare_to_stretch",
  thankyou: "thankyou",
};
