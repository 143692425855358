import React from "react"

const Modal = ({children, overflow}) => (
	<div className={"position-fixed vh-100 vw-100"} style={{
		backgroundColor: "rgba(0,0,0,0.5)",
		zIndex: 999999,
		top: 0, left: 0,
	}}>
		<div className="container-fluid">
			<div className="row justify-content-center align-items-center">
				<div className={"col-auto p-0 position-fixed rounded"} style={{top: "50%", transform: "translateY(-50%)", overflow}} >
					{children}
				</div>

			</div>
		</div>
	</div>
)

export default Modal
